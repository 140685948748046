import React from 'react'
import {
    DependentDropdown,
    PriceInput,
    CurrencyField,
    DeleteRowButton,
    HeaderFormatter
} from "../Formatters";
import styles from './TableConfig.module.css';
import { widthFormatter } from '../Formatters/widthFormatter';

export const LABOR_SPECIAL_RATE_COLS = [
    {
        text: '',
        dataField: '',
        isDummyColumn: true,
        style: {
            ...widthFormatter('deleteCol'),
            padding: 0
        },
        formatter: (col, row, rowI) => <DeleteRowButton rowId={row.id} rowIndex={rowI} tableType='laborSelectRate' />,
        align: 'center',
        footerClasses: [styles.footer, styles.noborder, styles.trashIcon]
    },
    {
        dataField: "asset",
        text: "Asset",
        style: widthFormatter('assetCol'),
        classes: styles.assetWidth,
        formatter: (col, row, rowI) => (
            <DependentDropdown cell={col} row={row} rowIndex={rowI} tableType={'laborSelectRate'} fieldName={"asset"} />
        )
    },
    {
        dataField: "component",
        text: "Component",
        style: widthFormatter('componentCol'),
        formatter: (col, row, rowI) => (
            <DependentDropdown cell={col} row={row} rowIndex={rowI} tableType={'laborSelectRate'} fieldName={"component"} isClearable={true} />
        )
    },
    {
        dataField: "repair",
        text: "Repair",
        style: widthFormatter('repairCol'),
        headerFormatter: (col) => (<HeaderFormatter col={col} centered={true} toolTipRequired={true} fieldName={'repair'} />),
        formatter: (col, row, rowI) => (
            <DependentDropdown cell={col} row={row} rowIndex={rowI} tableType={'laborSelectRate'} fieldName={"repair"} />
        )
    },
    {
        dataField: "repairCode",
        text: "Repair Code",
        headerAlign: 'center',
        align: 'left',
        style: widthFormatter('repairCodeCol'),
    },
    {
        dataField: "perc",
        text: "Perc.",
        style: widthFormatter('percCol'),
    },
    {
        dataField: "rate",
        text: "Rate",
        headerAlign: 'center',
        align: 'right',
        style: widthFormatter('rateTypeCol'),
        headerFormatter: (col) => (<HeaderFormatter col={col} centered={true} toolTipRequired={true} fieldName={'rate'} />),
        formatter: (c, r, rI) => {
            return `$${Number(c)?.toFixed(2)}`;
        }
    },
    {
        dataField: "total",
        text: "Total",
        headerAlign: 'center',
        align: 'right',
        style: widthFormatter('totalLaborCol'),
        formatter: (col, row, i) => {
            return <CurrencyField col={col} row={row} rowIndex={i} fieldName="total" tableType={'laborSelectRate'} />
        }
    }
];
