import React from "react";
import styles from './WhatsCovered.module.css';
import BootstrapTable from "react-bootstrap-table-next";
import { Row, Col, FormGroup, Card, CardBody, Progress } from "reactstrap";
import { ReactComponent as YesIcon } from '../../../../assets/images/checkmark.svg';
import { ReactComponent as NoIcon } from '../../../../assets/images/close.svg';
import { ReactComponent as StandardIcon } from '../../../../assets/images/ribbon.svg';
import { ReactComponent as HomeWarrIcon } from '../../../../assets/images/home-warranty.svg';
import { ReactComponent as ShieldIcon } from '../../../../assets/images/Vendor/Lennox/ComfortShield.svg';
import { ReactComponent as ProtectionPlusIcon } from '../../../../assets/images/Vendor/Rheem/Protection_Plus_Icon.svg';
import { ReactComponent as ClimateShieldIcon } from '../../../../assets/images/Vendor/Carrier/climate-shield.svg';
import { ReactComponent as GEShieldIcon } from '../../../../assets/images/Vendor/GE/comfort-protect-shield.svg';
import classnames from 'classnames';
import { getBrandContent } from '../../../BrandingWhiteLabel/BrandContent'
import { useContext } from "react";
import { SessionContext } from "../../../../context/SessionContext";
import InfoTooltip from "../../../shared/InfoTooltip/InfoTooltip";

const coverageTable = styles.coverageTable;

const YesIconComp = () => {
	return (
		<div className={styles.yesIcon}><YesIcon /></div>
	)
};

const NoIconComp = () => {
	return (
		<div className={styles.noIcon}><NoIcon /></div>
	)
};


const WhatsCovered = ({ props, onClick }) => {

	const context = useContext(SessionContext);
	const { accountName } = context;
	const isLennox = accountName.toLowerCase() === 'lennox';
	const isCarrier = accountName.toLowerCase() === 'carrier';
	const isRheem = accountName.toLowerCase() === 'rheem';
	const isGE = accountName.toLowerCase() === 'ge';

	const coverageColumns = [
		{
			dataField: "typeOfCoverage",
			text: "",
		},
		{
			dataField: "comfShieldLennox",
			text: (
				<div className="align-items-center d-flex">
					{isLennox && (
						<>
							<span>
								<ShieldIcon className={styles.shieldIcon} />
							</span>
							<span>Comfort Shield Lennox</span>{" "}
						</>
					)}
					{isRheem && (
						<>
							<span>
								<ProtectionPlusIcon className={styles.shieldIcon} />
							</span>
							<span>{getBrandContent(accountName).programName}</span>
						</>
					)}
					{isCarrier && (
						<>
							<span>
								<ClimateShieldIcon className={styles.shieldIcon} />
							</span>
							<span>{getBrandContent(accountName).programName}</span>
						</>
					)}
					{isGE && (
						<>
							<span>
								<GEShieldIcon className={styles.shieldIcon} />
							</span>
							<span className={styles.noWrapIcon}>{getBrandContent(accountName).programName}</span>
						</>
					)}
				</div>
			),
		},
		{
			dataField: "stdPartsWarranty",
			text: (
				<div className="align-items-center d-flex">
					<span>
						<StandardIcon className={styles.headerIcon} />
					</span>
					<span>Manufacturer's Std Parts Warranty</span>
				</div>
			),
		},
		{
			dataField: "typicalHomeWarranty",
			text: (
				<div className="align-items-center d-flex">
					<span>
						<HomeWarrIcon className={styles.headerIcon} />
					</span>
					<span>Typical Home Warranty</span>
				</div>
			),
		},
	];

	const coverageData = [
		{
			typeOfCoverage: "Parts Coverage",
			comfShieldLennox: (<YesIconComp />),
			stdPartsWarranty: (<YesIconComp />),
			typicalHomeWarranty: (<YesIconComp />)
		},
		{
			typeOfCoverage: (<div>Cost of Labor{accountName.toLowerCase() === "lennox" ? (<sup>2</sup>) : ""}</div>),
			comfShieldLennox: (<YesIconComp />),
			stdPartsWarranty: (<NoIconComp />),
			typicalHomeWarranty: (<YesIconComp />)
		},
		{
			typeOfCoverage: "Diagnostic Costs",
			comfShieldLennox: (<YesIconComp />),
			stdPartsWarranty: (<NoIconComp />),
			typicalHomeWarranty: (<NoIconComp />)
		},
		{
			typeOfCoverage: (<div>Refrigerant Coverage for Covered Repairs {accountName.toLowerCase() === "lennox" ? "(Tier 1 & Tier 2)" : ""}</div>),
			comfShieldLennox: (<YesIconComp />),
			stdPartsWarranty: (<NoIconComp />),
			typicalHomeWarranty: (<YesIconComp />)
		},
		{
			typeOfCoverage: "Quality Service by Your Installing Contractor",
			comfShieldLennox: (<YesIconComp />),
			stdPartsWarranty: (<YesIconComp />),
			typicalHomeWarranty: (<NoIconComp />)
		},
		{
			typeOfCoverage: "Fully Transferable",
			comfShieldLennox: (<YesIconComp />),
			stdPartsWarranty: (<NoIconComp />),
			typicalHomeWarranty: (<YesIconComp />)
		},
		{
			typeOfCoverage: "No Deductible or Service Fees",
			comfShieldLennox: (<YesIconComp />),
			stdPartsWarranty: (<YesIconComp />),
			typicalHomeWarranty: (<NoIconComp />)
		},
	];

	const coverageDataGE = [
		{
			typeOfCoverage: (<div>Cost of Labor{accountName.toLowerCase() === "lennox" ? (<sup>2</sup>) : ""}</div>),
			comfShieldLennox: (<YesIconComp />),
			stdPartsWarranty: (<NoIconComp />),
			typicalHomeWarranty: (<YesIconComp />)
		},
		{
			typeOfCoverage: "Diagnostic Costs",
			comfShieldLennox: (<YesIconComp />),
			stdPartsWarranty: (<NoIconComp />),
			typicalHomeWarranty: (<NoIconComp />)
		},
		{
			typeOfCoverage: (<div>Refrigerant Coverage for Covered Repairs {accountName.toLowerCase() === "lennox" ? "(Tier 1 & Tier 2)" : ""}</div>),
			comfShieldLennox: (<YesIconComp />),
			stdPartsWarranty: (<NoIconComp />),
			typicalHomeWarranty: (<YesIconComp />)
		},
		{
			typeOfCoverage: "Quality Service by Your Installing Contractor",
			comfShieldLennox: (<YesIconComp />),
			stdPartsWarranty: (<YesIconComp />),
			typicalHomeWarranty: (<NoIconComp />)
		},
		{
			typeOfCoverage: "Fully Transferable",
			comfShieldLennox: (<YesIconComp />),
			stdPartsWarranty: (<NoIconComp />),
			typicalHomeWarranty: (<YesIconComp />)
		},
		{
			typeOfCoverage: "No Deductible or Service Fees",
			comfShieldLennox: (<YesIconComp />),
			stdPartsWarranty: (<YesIconComp />),
			typicalHomeWarranty: (<NoIconComp />)
		},
	];

	return (
		<>
			<h1 className="h5">What's Covered</h1>
			{!isCarrier && !isLennox &&
				<>
					<p className={styles.desc}>
						{getBrandContent(accountName).programName} is designed to extend and
        enhance the Standard Manufacturer’s Limited Warranty for up to {accountName.toLowerCase() === "lennox" ? "twelve " : "ten "}
        years{accountName.toLowerCase() === "lennox" ? (<sup>1</sup>) : ""}. Customers receive additional benefits not available
        with the manufacturer’s warranty or other home warranty plans.
      </p>
					<div className={classnames(coverageTable, "m-lg-0")}>
						<BootstrapTable
							keyField="planBenefits"
							data={!isGE ? coverageData : coverageDataGE}
							columns={coverageColumns}
							bordered={true}
							bootstrap4
							wrapperClasses="table-responsive-lg"
						/>
					</div>
					{accountName.toLowerCase() === "lennox" ? (
						<>
							<p className="legal mb-1">
								<sup>1</sup>Twelve year terms are only available for DLSC equipment.
          </p>
							<p className="legal mb-4">
								<sup>2</sup>Labor warranty is an option within Lennox’s
            manufacturer’s warranty for eligible product.
          </p>
						</>
					) : (
							""
						)}
					<h2 className="h5 mb-2 mt-4">Available On</h2>
					<p className="mb-0">
						HVAC/Residential Equipment. See{" "}
						{!isGE && <span className={styles.link} onClick={onClick}>
							plan pricing
        </span>}
						{isGE && <span className={styles.link} onClick={onClick}>
							plan pricing
        </span>}

						{" "}
        for details.
      </p>
				</>
			}
			{isCarrier &&
				<>
					<p className={styles.desc}>
						{getBrandContent(accountName).programName} Extended Service Agreements (ESAs) are designed to wrap around
            the {getBrandContent(accountName).clientName} manufacturer's warranty to provide your customers total
            combined coverage for both parts and labor throughout the duration of their manufacturer’s warranty.
          </p>
          <Row>
            <Col className="mb-2">
              <div className={styles.measureheader}>
                <div className={styles.circleLabel}>
                    <span className={classnames(styles.OEMCov, styles.circle)}></span>
                    <p className="mb-0">OEM Coverage</p>
                </div>
                <div className={styles.circleLabel}>
                    <span className={classnames(styles.planCov, styles.circle)}></span>
                    <p className="mb-0">{getBrandContent(accountName).programName} Coverage</p>
                </div>
              </div>            
            </Col>
          </Row>
          <Row className="twoColWrapper">
            <Col xs="12" sm="6" className={styles.twoColWrapperXS}>
              <Card className="px-3 pt-3">
                <h1 className="h6">OEM: 5-year Parts &amp; 3-year Labor</h1>
                <p className={styles.desc}>
                  Customers that chose the 5-year parts &amp; 3-year labor manufacturer's warranty 
                  can purchase an ESA to extend their labor coverage an additional two years.
                </p>
                <p className={classnames(styles.desc, "mb-0")}>Product Coverage</p>
                <div>
                  <div className={styles.progressBars}>
                    <div className={styles.progressBarsTop}>
                      <div className={styles.oemSubHeadLeft}>OEM - <strong>3 Yrs</strong></div>
                      <div className={styles.esaSubHeadRight}>ESA - <strong>2 Yrs</strong></div>
                    </div>                  
                    <Progress multi className={styles.progress}>                  
                      <Progress bar className={classnames(styles.progress, styles.oem)} value={3/5*100}></Progress>                  
                      <Progress bar className={classnames(styles.progress, styles.esa)} value={2/5*100}></Progress>
                    </Progress>
                    <div className={styles.progressTimeline}>
                      <p className={classnames('h5', styles.carrierProgressLabel)}>{props?.isCanadaState ? "Labour" : "Labor"}</p>
                      <p className={'h5'}>5 Yrs</p>
                    </div>
                  </div>
                </div>
                <div className="mt-1 mb-1">
                  <div className={styles.progressBars}>
                    <div className={styles.oemSubHead}>ESA - <strong>2 Yrs</strong></div>
                    <Progress bar className={classnames(styles.progress, styles.oem)} value={100}></Progress>
                    <div className={styles.progressTimeline}>
                      <div className={classnames('h5', styles.partsSubhead, styles.carrierProgressLabel)}>
                        Parts
                        <div className={styles.infoIcon}>
                          <InfoTooltip 
                            iconType="Info" 
                            message="Factory parts warranty assumes equipment is eligible for parts extension with the equipment OEM and that registration is done within the OEM guidelines." 
                          />
                        </div>
                      </div>
                      <p className={'h5'}>5 Yrs</p>
                    </div>
                  </div>
                </div>              
              </Card>
            </Col>
            <Col xs="12" sm="6">
              <Card className="px-3 pt-3">
                <h1 className="h6">OEM: 10-Year Parts</h1>
                <p className={styles.desc}>
                  Customers that chose the 10-year parts manufacturer's warranty can purchase 
                  an ESA to add labor coverage for up to 10 years.
                </p>
                <p className={classnames(styles.desc, "mb-0", "mt-4")}>Product Coverage</p>
                <div>
                  <div className={styles.progressBars}>
                    <div className={styles.oemSubHead}>ESA - <strong>Up to 10 Yrs</strong></div>
                    <Progress bar className={classnames(styles.progress, styles.esa)} value={100}></Progress>
                    <div className={styles.progressTimeline}>
                      <p className={classnames('h5', styles.carrierProgressLabel)}>{props?.isCanadaState ? "Labour" : "Labor"}</p>
                      <p className={'h5'}>10 Yrs</p>
                    </div>
                  </div>
                </div>  
                <div className="mt-1 mb-1">
                  <div className={styles.progressBars}>
                    <div className={styles.oemSubHead}>OEM - <strong>10 Yrs</strong></div>
                    <Progress bar className={classnames(styles.progress, styles.oem)} value={100}></Progress>
                    <div className={styles.progressTimeline}>
                      <div className={classnames('h5', styles.partsSubhead, styles.carrierProgressLabel)}>
                        Parts
                        <div className={styles.infoIcon}>
                          <InfoTooltip
                            iconType="Info"
                            message="Factory parts warranty assumes equipment is eligible for parts extension with the equipment OEM and that registration is done within the OEM guidelines."
                          />
												</div>
											</div>
											<p className={'h5'}>10 Yrs</p>
										</div>
									</div>
								</div>
							</Card>
						</Col>
					</Row>
					<Row>
						<Col className="mt-2">
							<h1 className="h5">
								Extended Service Agreement Benefits
                <span className="required-indicator">*</span>
							</h1>
							<Row className="ml-0">
								<Col sm="3" className="ml-0 pl-0">
									<ul className={classnames(styles.agreement, "pl-3")}>
										<li className="mb-2">Covers Labor</li>
										<li>Covers Diagnostics</li>
									</ul>
								</Col>
								<Col sm="4" className="ml-0 pl-0">
									<ul className={styles.agreement}>
										<li className="mb-2">Fully Transferrable</li>
										<li>No Deductible or Service Fees</li>
									</ul>
								</Col>
								<Col sm="7"></Col>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col className="mt-2">
							<h2 className="h5 mb-2">Available On</h2>
							<p className="mb-0">
								HVAC/Residential Equipment. See{" "}
								<span className={styles.link} onClick={onClick}>
									plan pricing
                </span>{" "}
                for details.
              </p>
						</Col>
					</Row>
					<Row>
						<Col>
							<p className="mt-3">
								*Depending on the plan purchased, benefits will vary. Limitations and exclusions apply.
								Please see the full terms and conditions for further details.
              </p>
            </Col>
          </Row>
        </>
      }
      {isLennox  && 
        <>
          <p className={styles.desc}>
            {getBrandContent(accountName).programName} Extended Service Agreements (ESAs) are designed to wrap around 
            the {getBrandContent(accountName).clientName} manufacturer's warranty to provide your customers total 
            combined coverage for both parts and labor throughout the duration of their manufacturer’s warranty.
          </p>
          <Row>
            <Col className="mb-2">
              <div className={styles.measureheader}>
                <div className={styles.circleLabel}>
                    <span className={classnames(styles.OEMCovLennox, styles.circle)}></span>
                    <p className="mb-0">OEM Coverage</p>
                </div>
                <div className={styles.circleLabel}>
                    <span className={classnames(styles.planCovLennox, styles.circle)}></span>
                    <p className="mb-0">{getBrandContent(accountName).programName} Coverage</p>
                </div>
              </div>            
            </Col>
          </Row>
          <Row className="twoColWrapper">
            <Col xs="12" sm="6" className={styles.twoColWrapperXS}>
              <Card className="px-3 pt-3">
                <h1 className="h6">OEM: 7 or 10-year Parts &amp; 3-year Labor</h1>
                <p className={styles.desc}>
                  Depending on the equipment series, the manufacturer's warranty varies. Customers can extend their 
                  labor coverage for an additional four or seven years to match the length of their OEM parts warranty.
                </p>
                <p className={classnames(styles.desc, "mb-0")}>Product Coverage</p>
                <div>
                  <div className={styles.progressBars}>
                    <div className={styles.progressBarsTop}>
                      <div className={styles.oemSubHeadLeft}>OEM - <strong>3 Yrs</strong></div>
                      <div className={styles.esaSubHeadRight}>ESA - <strong>4 or 7 Yrs</strong></div>
                    </div>                  
                    <Progress multi className={styles.progress}>                  
                      <Progress bar className={classnames(styles.progress, styles.oem)} value={3/10*100}></Progress>                  
                      <Progress bar className={classnames(styles.progress, styles.esaLennox)} value={7/10*100}></Progress>
                    </Progress>
                    <div className={styles.progressTimeline}>
                      <p className={classnames('h5', styles.carrierProgressLabel)}>{props?.isCanadaState ? "Labour" : "Labor"}</p>
                      <p className={'h5'}>7 or 10 Yrs</p>
                    </div>
                  </div>
                </div>
                <div className="mt-1 mb-1">
                  <div className={styles.progressBars}>
                    <div className={styles.oemSubHead}>OEM - <strong>7 or 10 Yrs</strong></div>
                    <Progress bar className={classnames(styles.progress, styles.oem)} value={100}></Progress>
                    <div className={styles.progressTimeline}>
                      <div className={classnames('h5', styles.partsSubhead, styles.carrierProgressLabel)}>
                        Parts
                        <div className={styles.infoIcon}>
                          <InfoTooltip
                            iconType="Info" 
                            message="Factory parts warranty assumes equipment is eligible for parts extension with the equipment OEM and that registration is done within the OEM guidelines." 
                           />
                        </div>
                      </div>
                      <p className={'h5'}>7 or 10 Yrs</p>
                    </div>
                  </div>
                </div>              
              </Card>
            </Col>
            <Col Col xs="12" sm="6">
              <Card className="px-3 pt-3">
                <h1 className="h6">OEM: 10 or 12-year Parts</h1>
                <p className={styles.desc}>
                  Customers with equipment that comes with a parts only OEM warranty can purchase an 
                  Extended Service Agreement to add labor coverage for up to ten or twelve years.
                </p>
                <p className={classnames(styles.desc, "mb-0", "mt-4")}>Product Coverage</p>
                <div>
                  <div className={styles.progressBars}>
                    <div className={styles.oemSubHead}>ESA - <strong>Up to 10 or 12 Yrs</strong></div>
                    <Progress bar className={classnames(styles.progress, styles.esaLennox)} value={100}></Progress>
                    <div className={styles.progressTimeline}>
                      <p className={classnames('h5', styles.carrierProgressLabel)}>{props?.isCanadaState ? "Labour" : "Labor"}</p>
                      <p className={'h5'}>10 or 12 Yrs</p>
                    </div>
                  </div>
                </div>  
                <div className="mt-1 mb-1">
                  <div className={styles.progressBars}>
                    <div className={styles.oemSubHead}>OEM - <strong>Up to 10 or 12 Yrs</strong></div>
                    <Progress bar className={classnames(styles.progress, styles.oem)} value={100}></Progress>
                    <div className={styles.progressTimeline}>
                      <div className={classnames('h5', styles.partsSubhead, styles.carrierProgressLabel)}>
                        Parts
                        <div className={styles.infoIcon}>
                          <InfoTooltip 
                            iconType="Info" 
                            message="Factory parts warranty assumes equipment is eligible for parts extension with the equipment OEM and that registration is done within the OEM guidelines." 
                          />
                        </div>
                      </div>
                      <p className={'h5'}>10 or 12 Yrs</p>
                    </div>
                  </div>
                </div>  
              </Card>
            </Col>
          </Row>
          <Row>
            <Col className="mt-2">
              <h1 className="h5">
                Extended Service Agreement Benefits
                <span className="required-indicator">*</span>
              </h1>
              <Row className="ml-0">
                <Col sm="3" className="ml-0 pl-0">
                  <ul className={classnames(styles.agreementLennox, "pl-3")}>
                    <li className="mb-2">Covers the Cost of Labor</li>
                    <li>Covers Diagnostics</li>
                  </ul>
                </Col>
                <Col sm="4" className="ml-0 pl-0">
                  <ul className={styles.agreementLennox}>
                    <li className="mb-2">Fully Transferrable</li>
                    <li>No Deductible or Service Fees</li>
                  </ul>
                </Col>
                <Col sm="7"></Col>
              </Row>            
            </Col>
          </Row>
          <Row>
            <Col className="mt-2">
              <h2 className="h5 mb-2">Available On</h2>
              <p className="mb-0">
                HVAC/Residential Equipment. See{" "}
                <span className={styles.link} onClick={onClick}>
                  plan pricing
                </span>{" "}
                for details.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p className="mt-3">
                *Depending on the plan purchased, benefits will vary. Limitations and exclusions apply. 
                Please see the full terms and conditions for further details.
              </p>
            </Col>
          </Row>
        </>
      }
    </>
  );
};

export default WhatsCovered