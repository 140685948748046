// will run when a table's TOTALS change and returns ONLY the totals for the specific table.
const calculateSubTotals = (newData, table) => {
    const authVals = [];
    newData.forEach(rec => authVals.push(rec.authorized))
    //reduce will thorw TypeError: Reduce of empty array with no initial value if second parameter is not filled. 
    //see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Reduce_of_empty_array_with_no_initial_value
    const newAuth = authVals.reduce((acc, item) => acc + item, 0)

    const reqVals = [];
    newData.forEach(rec => reqVals.push(rec.requested))
    const newReq = reqVals.reduce((acc, item) => acc + item, 0)

    const reqPrice = [];
    newData.forEach(rec => reqPrice.push(rec.unitPrice))
    const newReqPrice = reqPrice.reduce((acc, item) => acc + item, 0)

    return {
        authorized: newAuth,
        requested: newReq,
        reqPrice: newReqPrice
    }
}

// will run on any dispatch that affects totals. Returns ENTIRE totals object for all tables.
const calculateTotals = (newTotals) => {
    const { labor, parts, mileage, tax } = newTotals
    const authorized = labor.authorized + parts.authorized + mileage.authorized + tax.authorized
    const requested = labor.requested + parts.requested + mileage.requested + tax.requested
    return {
        ...newTotals,
        final: {
            requested,
            authorized, reqPrice: parts.reqPrice
        }
    }
}

// FUTURE STORY: calculateTax () {...} helper func
// run on every dispatch that affects totals.

/**
 * ====================================
 * ========== BEGIN REDUCER ===========
 * ====================================
 */

export default function reducer(state, action) {
    // destructure values that should be present in every payload
    const { table, rowId, rowIndex } = action.payload
    const nextIndex = rowIndex + 1;

    switch (action.type) {
        // ==== INITIAL API PULL ONLY ==== //
        case ('INITIATE_DATA'): {
            const { labor, parts, mileage, preapprovedAmount, taxRate, coverages, negotiatedRateTypes, assets, rateValues } = action.payload

            const aggregate = (entry) => {

                entry.authorized = entry.authorized || 0;
                entry.requested = entry.requested || 0;
                entry.authEdited = false
            }

            //for (let i = 0; i < labor.length; i++) {
            //    labor[i].rateType = negotiatedRateTypes[i]
            //    labor[i].unitPrice = negotiatedRateTypes[i].rate
            //}

            //labor.forEach(entry => entry.coverage = coverages[0])
            //parts.forEach(entry => entry.coverage = coverages[0])
            //console.log(taxRate)
            labor.forEach(entry => aggregate(entry));
            parts.forEach(entry => aggregate(entry));
            mileage.forEach(entry => aggregate(entry));
            taxRate.forEach(entry => aggregate(entry))

            const subtotals = {
                ...state.totals,
                labor: labor.length > 0 ? calculateSubTotals(labor, 'labor') : { authorized: 0, requested: 0 },
                parts: parts.length > 0 ? calculateSubTotals(parts, 'parts') : { authorized: 0, requested: 0, reqPrice: 0 },
                mileage: mileage.length > 0 ? calculateSubTotals(mileage, 'mileage') : { authorized: 0, requested: 0 },
                tax: taxRate.length > 0 ? calculateSubTotals(taxRate, 'tax') : { authorized: 0, requested: 0 },
            }
            //console.log(taxRate, subtotals)
            const totals = calculateTotals(subtotals)
            const remainingCoverage = preapprovedAmount - totals.final.authorized

            return {
                ...state,
                negotiatedRateTypes,
                preapprovedAmount,
                labor,
                parts,
                mileage,
                taxRate,
                totals,
                remainingCoverage,
                assets,
                coverages,
                tax: taxRate,
                rateValues: rateValues
            }
        }

        /** ============================================
         *  ====== REUSABLE IN MULTIPLE TABLES =========
         *  ============================================
         */

        case ('ADD_ROW'): {
            const { entry } = action.payload;
            const currentTable = state[table];

            // chooses the first un-selected rateType and applies it to the whole row
            if (table === 'labor') {
                let typesCopy = [...state.negotiatedRateTypes]
                state.negotiatedRateTypes.forEach((rT, index) => {
                    currentTable.forEach(tableRow => {
                        if (tableRow.rateType.data === rT.data) {
                            typesCopy.splice(typesCopy.indexOf(tableRow.rateType), 1);
                        }
                    })
                })
                const newRateType = typesCopy[0];
                if (newRateType != null) {
                    entry.rateType = newRateType;
                    entry.unitPrice = newRateType.rate;
                    entry.requested = entry.qty * entry.unitPrice;
                    entry.authorized = entry.qty * entry.unitPrice;
                }
            }
            if (table === 'parts') {
                entry.qty = 1;
                entry.unitPrice = 0;
            }
            if (table === 'labor' || table === 'parts') {
                entry.coverage = state.coverages[0]
            };
            let tableRowFix;

            if (table === 'parts' && state[table].length >= 25) {
                tableRowFix = currentTable;
            }
            else if (table == 'labor' && state[table].length >= state.negotiatedRateTypes.length) {
                tableRowFix = currentTable;
            }
            else
            {
                tableRowFix = currentTable.concat(entry); 
            }
            const added = tableRowFix;
            // mocks the whole "totals" object
            const subTotaler = {
                ...state.totals,
                [table]: calculateSubTotals(added, table),
            }

            // returns a whole "totals" object based on updated values above.
            const totals = calculateTotals(subTotaler);

            // FUTURE STORY: calculate taxes on initial load.
            const remainingCoverage = state.preapprovedAmount - totals.final.authorized

            return {
                ...state,
                [table]: added,
                totals,
                remainingCoverage,
                isTableEdited: true,
            }
        }

        case ('REMOVE_ROW'): {
            const currentTable = state[table];
            const removed = currentTable.filter(data => data.id !== rowId);

            const subTotaler = {
                ...state.totals,
                [table]: calculateSubTotals(removed, table),
            }

            // returns a whole "totals" object based on updated values above.
            const totals = calculateTotals(subTotaler);
            const remainingCoverage = state.preapprovedAmount - totals.final.authorized

            return {
                ...state,
                [table]: removed,
                totals,
                remainingCoverage,
                isTableEdited: true,
            }
        }

        case ('ADJUST_UNIT_PRICE'): {
            const { unitPrice } = action.payload;
            const currentTable = state[table];
            const itemToEdit = currentTable.find(data => data.id === rowId);
            const { qty, authEdited, authorized, tripType, isAuthDisabled } = itemToEdit

            let calcUnit = 0;
            if (table === 'labor' || table === 'parts') {
                calcUnit = Math.abs(qty * unitPrice)
            }
            else if (table === 'mileage')
            {
                if (tripType?.data === 'roundTrip')
                {
                    
                    calcUnit = (qty >= 60) ? Math.abs((qty - 60) * unitPrice) : 0
                }
                else if (tripType?.data === 'trip' ) {
                    
                    calcUnit = unitPrice;
                }
                else if (tripType?.data === 'perMile' || tripType?.data === 'flatRate')
                {
                    calcUnit = Math.abs(qty * unitPrice);
                }
                else
                {
                    calcUnit = (qty >= 30) ? Math.abs(2 * (qty - 30) * unitPrice) : 0
                }
            };
            const newRequested = calcUnit;
            //const newRequested = (table === 'mileage' && tripType === 'roundTrip') ? (qty >= 60) ?
            //    Math.abs((qty - 60) * unitPrice) : 0
            //    : (qty >= 30) ? Math.abs(2 * (qty - 30) * unitPrice) : 0; /*PR-SEU - 3817*/
            const newAuthorized = !authEdited ? newRequested : authorized;
            const updated = {
                ...itemToEdit,
                requested: newRequested,
                authorized: isAuthDisabled ? 0 : newAuthorized,
                unitPrice
            };
            const inserted = [
                ...state[table].slice(0, rowIndex),
                updated,
                ...state[table].slice(nextIndex)
            ];

            const subTotaler = {
                ...state.totals,
                [table]: calculateSubTotals(inserted, table),
            }

            // returns a whole "totals" object based on updated values above.
            const totals = calculateTotals(subTotaler);
            const remainingCoverage = state.preapprovedAmount - totals.final.authorized

            return {
                ...state,
                [table]: inserted,
                totals,
                remainingCoverage,
                isTableEdited: true,
            }
        }

        case ('ADJUST_QTY'): {
            const { qty } = action.payload;
            const currentTable = state[table];
            const itemToEdit = currentTable.find(data => data.id === rowId);
            const { unitPrice, authEdited, authorized, tripType, isAuthDisabled } = itemToEdit;
   
            let calcUnit = 0;
            if (table === 'labor' || table === 'parts') {
                calcUnit = Math.abs(qty * unitPrice ?? 0)
            }
            else if (table === 'mileage')
            {
                if (tripType?.data === 'roundTrip')
                {

                    if (state?.rateValues?.servicerRadius && state?.rateValues?.servicerRadius > 0)
                        calcUnit = (qty >= 60) ? Math.abs((qty - (2 * (state?.rateValues?.servicerRadius))) * unitPrice) : 0;
                    else
                        calcUnit = (qty >= 60) ? Math.abs((qty - 60) * unitPrice) : 0;
                }
                else if (tripType?.data === 'trip' ) {
                   
                    calcUnit = unitPrice;
                }
                else if (tripType?.data === 'perMile' || tripType?.data === 'flatRate')
                {
                    
                    calcUnit = Math.abs(qty * unitPrice);
                }
                else
                {
                    if (state?.rateValues?.servicerRadius && state?.rateValues?.servicerRadius > 0)
                        calcUnit = (qty >= 30) ? Math.abs(2 * (qty - state?.rateValues?.servicerRadius) * unitPrice) : 0;
                    else
                        calcUnit = (qty >= 30) ? Math.abs(2 * (qty - 30) * unitPrice) : 0;
                }
            };

            const newRequested = calcUnit;
            //const newRequested = (table === 'mileage' && tripType === 'roundTrip') ? (qty >= 60) ?
            //    Math.abs((qty - 60) * unitPrice) : 0
            //    : (qty >= 30) ? Math.abs(2 * (qty - 30) * unitPrice) : 0; /*PR-SEU - 3817*/
            const newAuthorized = !authEdited ? newRequested : authorized;
            const updated = {
                ...itemToEdit,
                requested: newRequested,
                authorized: isAuthDisabled ? 0 : newAuthorized,
                qty
            };
            const inserted = [
                ...state[table].slice(0, rowIndex),
                updated,
                ...state[table].slice(nextIndex)
            ];
            const subTotaler = {
                ...state.totals,
                [table]: calculateSubTotals(inserted, table),
            }

            // returns a whole "totals" object based on updated values above.
            const totals = calculateTotals(subTotaler);
            const remainingCoverage = state.preapprovedAmount - totals.final.authorized

            return {
                ...state,
                [table]: inserted,
                totals,
                remainingCoverage,
                isTableEdited: true,
            }
        }

        case ('EDIT_AUTHORIZED_AMOUNT'): {
            const { authorized } = action.payload;
            const currentTable = state[table];
            const itemToEdit = currentTable.find(row => row.id === rowId);

            // if no meaningful change, do nothing.
            // TODO: improve logic to allow for string/number comparisons
            if (
                (!authorized && !itemToEdit.authorized)
                || (parseFloat(authorized) == itemToEdit.authorized)
            ) return state;

            const updated = {
                ...itemToEdit,
                authEdited: true,
                authorized: parseFloat(authorized)
            };
            const inserted = [
                ...state[table].slice(0, rowIndex),
                updated,
                ...state[table].slice(nextIndex)
            ];

            const subTotaler = {
                ...state.totals,
                [table]: calculateSubTotals(inserted, table),
            }

            // returns a whole "totals" object based on updated values above.
            const totals = calculateTotals(subTotaler);
            const remainingCoverage = state.preapprovedAmount - totals.final.authorized

            return {
                ...state,
                [table]: inserted,
                totals,
                remainingCoverage,
                isTableEdited: true,
            }
        }

        case ('SELECT_COVERAGE'): {
            const { coverage } = action.payload
            const currentTable = state[table]
            const itemToEdit = currentTable[rowIndex];
            const updated = {
                ...itemToEdit,
                coverage
            }

            const inserted = [
                ...state[table].slice(0, rowIndex),
                updated,
                ...state[table].slice(nextIndex)
            ];

            //debugger;
            // coverage does not impact calculations. no additional comp necessary.
            return {
                ...state,
                [table]: inserted,
                isTableEdited: true,
            }
        }

        /** ============================================
         *  ============= TABLE-SPECIFIC ===============
         *  ============================================
         */

        // SPECIFIC TO PARTS TABLE; NO TABLE INPUT NEEDED
        case ('UPDATE_DESCRIPTION'): {
            const { description } = action.payload
            const { parts } = state;
            const updated = { ...parts[rowIndex], description }

            const inserted = [
                ...parts.slice(0, rowIndex),
                updated,
                ...parts.slice(nextIndex)
            ];

            return {
                ...state,
                parts: inserted,
                isTableEdited: true,
            }
        }

        // SPECIFIC TO PARTS TABLE; NO TABLE INPUT NEEDED
        case ('UPDATE_PART_NUMBER'): {
            const { partNumber } = action.payload
            const { parts } = state;
            const updated = { ...parts[rowIndex], partNumber }

            const inserted = [
                ...parts.slice(0, rowIndex),
                updated,
                ...parts.slice(nextIndex)
            ];

            return {
                ...state,
                parts: inserted,
                isTableEdited: true,
            }
        }

        // SPECIFIC TO LABOR TABLE; NO TABLE INPUT NEEDED
        case ('RATE_TYPE'): {
            const { rateType } = action.payload
            const { labor } = state;
            const itemToEdit = labor[rowIndex]

            const unitPrice = action.payload.rateType.rate;
            const billingCodeId = action.payload.rateType.billingCodeId;
            const requested = unitPrice * itemToEdit.qty;
            const authorized = itemToEdit.authEdited ? itemToEdit.authorized : requested

            const updated = {
                ...labor[rowIndex],
                rateType,
                billingCodeId,
                unitPrice,
                requested,
                authorized,
            };

            const inserted = [
                ...labor.slice(0, rowIndex),
                updated,
                ...labor.slice(nextIndex)
            ]

            const subTotaler = {
                ...state.totals,
                labor: calculateSubTotals(inserted, 'labor'),
            }

            // returns a whole "totals" object based on updated values above.
            const totals = calculateTotals(subTotaler);

            const remainingCoverage = state.preapprovedAmount - totals.final.authorized

            return {
                ...state,
                labor: inserted,
                totals,
                remainingCoverage,
                isTableEdited: true,
            }
        }

        case ('TRIP_TYPE'): {
            const { mileage } = state;
            const itemToEdit = mileage[rowIndex];
            const tripType = action.payload.tripType
            /*const requested = multiplier * row.unitPrice * row.qty*/
            //const requested = (tripType === 'roundTrip') ? (row.qty >= 60) ?
            //    Math.abs((row.qty - 60) * row.unitPrice) : 0
            //    : (row.qty >= 30) ? Math.abs(2 * (row.qty - 30) * row.unitPrice) : 0; /*PR-SEU - 3817*/

            let requested = 0;
            if (tripType?.data === 'roundTrip')
            {
                itemToEdit.unitPrice = state?.rateValues?.roundTripRate > 0 ? state?.rateValues?.roundTripRate : itemToEdit.unitPrice;

                if (state?.rateValues?.servicerRadius && state?.rateValues?.servicerRadius > 0)
                    requested = (itemToEdit.qty >= 60) ? Math.abs((itemToEdit.qty - (2 * (state?.rateValues?.servicerRadius))) * itemToEdit.unitPrice) : 0;
                else
                    requested = (itemToEdit.qty >= 60) ? Math.abs((itemToEdit.qty - 60) * itemToEdit.unitPrice) : 0;
            }
            else if (tripType?.data === 'oneWay' )
            {
                itemToEdit.unitPrice = state?.rateValues?.onewayTripRate > 0 ? state?.rateValues?.onewayTripRate : itemToEdit.unitPrice;

                if (state?.rateValues?.servicerRadius && state?.rateValues?.servicerRadius > 0)
                    requested = (itemToEdit.qty >= 30) ? Math.abs(2 * (itemToEdit.qty - state?.rateValues?.servicerRadius) * itemToEdit.unitPrice) : 0;
                else
                    requested = (itemToEdit.qty >= 30) ? Math.abs(2 * (itemToEdit.qty - 30) * itemToEdit.unitPrice) : 0;
            }
            else if (tripType?.data === 'trip' )
            {
                requested = itemToEdit.unitPrice;
            }
            else if (tripType?.data === 'perMile' || tripType?.data === 'flatRate')
            {
                requested = Math.abs(itemToEdit.qty * itemToEdit.unitPrice);
            }
            else
            {
                requested = (itemToEdit.qty >= 30) ? Math.abs(2 * (itemToEdit.qty - 30) * itemToEdit.unitPrice) : 0
            }

            const authorized = itemToEdit.authEdited ? itemToEdit.authorized : requested

            const updated = {
                ...itemToEdit,
                tripType,
                requested,
                authorized
            }

            const inserted = [
                ...mileage.slice(0, rowIndex),
                updated,
                ...mileage.slice(nextIndex)
            ]

            const subTotaler = {
                ...state.totals,
                mileage: calculateSubTotals(inserted, 'mileage'),
            }

            const totals = calculateTotals(subTotaler);

            return {
                ...state,
                mileage: inserted,
                totals,
                isTableEdited: true,
            }
        }

        // ======== TAX TABLE ======== //
        // UPDATES WITH EACH RELEVANT UPDATE
        case ('EDIT_REQUESTED_TAX'): {
            const { rowIndex, requested } = action.payload;
            const { tax } = state;

            const itemToEdit = tax[rowIndex];

            const updated = {
                ...itemToEdit,
                requested,
                authorized: itemToEdit.authEdited ? itemToEdit.authorized : requested
            };
            const inserted = [
                ...tax.slice(0, rowIndex),
                updated,
                ...tax.slice(nextIndex)
            ];

            const subTotaler = {
                ...state.totals,
                tax: calculateSubTotals(inserted, 'tax'),
            }

            // returns a whole "totals" object based on updated values above.
            const totals = calculateTotals(subTotaler);
            const remainingCoverage = state.preapprovedAmount - totals.final.authorized

            return {
                ...state,
                tax: inserted,
                totals,
                remainingCoverage,
                isTableEdited: true,

            }
        }

        case ('PARTS_DETAILS'): {
            const { details, rowIndex } = action.payload;
            const { parts } = state;
            state.parts[rowIndex].unitPrice = 0;
            //if ((details.length == 1 && details[0] == "nla") || details.length > 1 || details.length == 0) {
            //    state.parts[rowIndex].unitPrice = 0;
            //}
            const itemToEdit = state.parts[rowIndex]
            const requested = itemToEdit.unitPrice * itemToEdit.qty;

            const isAuthDisabled = (details.includes('nla') || details.includes('oem')) ? true : false;

            const updated = {
                ...itemToEdit,
                details,
                isAuthDisabled,
                authorized: isAuthDisabled ? 0 : requested,
                requested
            }

            const inserted = [
                ...parts.slice(0, rowIndex),
                updated,
                ...parts.slice(nextIndex)
            ];

            const subTotaler = {
                ...state.totals,
                parts: calculateSubTotals(inserted, 'parts'),
            }

            // returns a whole "totals" object based on updated values above.
            const totals = calculateTotals(subTotaler);
            const remainingCoverage = state.preapprovedAmount - totals.final.authorized
            return {
                ...state,
                parts: inserted,
                remainingCoverage,
                totals,
                isTableEdited: true,
            }

        }
        case "TOGGLE_READ_ONLY_MODE": {
            const { isTableReadOnly } = action.payload
            return {
                ...state,
                isTableReadOnly,
            }
        }

        default:
            return state;
    }
}