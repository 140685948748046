export const countryConstants = {
    US: {
        currency: {
            symbol: '$',
            abbreviation: 'USD',
            thousandsDelimiter: ',',
            decimalDelimiter: '.'
        },
        date: {
            momentJsFormat: 'MM/DD/YYYY',
            momentJsLongFormat: 'h:MM a, MMMM Do, YYYY', 
            momentJsLongFormatWithTimezone: 'hh:MM a z, MMMM DD, YYYY',
            momentJsLocale: 'us',           
            delimiter: '/',
            cleavePattern: ['m', 'd', 'Y']
        },
        phone: {
            countryCode: '+1',
            maxInputLength: null,
            regex: '',  // FOR FUTURE PHONE VALIDATION PATTERNS
        }
    },
   
    // FRANCE
    FR: {
        currency: {
            symbol: '€',
            abbreviation: 'EUR',
            thousandsDelimiter: ' ',
            decimalDelimiter: ','
        },
        date: {
            momentJsFormat: 'DD/MM/YYYY',
            momentJsLongFormat: 'HH:MM, DD MMMM YYYY',
            momentJsLongFormatWithTimeZone: 'DD MMMM YYYY, HH:MM z',
            momentJsLocale: 'fr',           
            delimiter: '/',
            cleavePattern: ['d', 'm', 'Y']
        },
        phone: {
            countryCode: '+33',
            maxInputLength: 14,
            regex: '',  // FOR FUTURE PHONE VALIDATION PATTERNS
        },
        translations: {
            paymentGatewayText: {
                paymentInfo: "Informations de paiement",
                cardDetails: "Détails de la carte",
                paymentAmount: "Montant du paiement",
                ccNumber: "Numéro de carte de crédit",
                expiry: "Date d’expiration",
                securityCode: "Code de sécurité",
                billingAddress: "Adresse de facturation",
                useMailing: "Utiliser mon adresse postale",
                back: "Retour",
                orderSummary: "Récapitulatif de la commande",
                planTotal: "Montant total du plan",
                tax: "TVA",
                orderTotal: "Montant total de la commande",
                pay: "Payer",
                errors: {
                    internal: "Une erreur s’est produite lors du traitement de votre paiement. Ce problème est de notre ressort, pas du vôtre. Votre carte n’a pas été débitée, veuillez réessayer plus tard.",
                    internalSaveError: "Une erreur s'est produite lors de l'enregistrement de votre carte. Le problème est de notre côté, pas du vôtre. Veuillez réessayer plus tard.",
                    ccNumRequired: "Champs requis",
                    ccNumInvalid: "Le numéro de carte de crédit n’est pas valide.",
                    ccNotAccepted: null,
                    expiryRequired: "Champs requis",
                    cardExpired: "La carte de crédit utilisée a expiré.",
                    cvvRequired: "Champs requis",
                    cvvInvalid: "Le code de sécurité n’est pas valide.",
                    billingAddressMatch: "L’adresse de facturation ne correspond pas aux données de la carte.",
                    funds: "Malheureusement, il n’y a pas suffisamment de fonds disponibles sur cette carte. Veuillez essayer de payer avec une autre carte.",
                    declined: "La carte de crédit saisie a été refusée, veuillez essayer de payer avec une autre carte de crédit.",
                    tryAgain: "Un problème est survenu lors du traitement de votre paiement. Veuillez réessayer."
                }
            },
            paymentGatewayErrorText: {
                title: "Oups, quelque chose s’est mal passé de notre côté.",
                p1: "Notre système n’est pas en mesure de traiter votre demande pour le moment.",
                pleaseCall: "Veuillez réessayer plus tard ou appelez-nous ",
                tokenError: {
                    title: "Notre système n’est pas en mesure de traiter votre demande pour le moment.",
                    p1Prefix: "Utilisez le bouton Précédent du navigateur pour revenir au site Internet de ",
                    p1Suffix: "et réessayez.",
                    pleaseCall: "Si les problèmes persistent, appelez notre équipe d’assistance au "
                },
                expeditedError: {
                    title: "Nous sommes toujours en train de traiter ce contrat.",
                    p1: "Veuillez patienter quelques minutes et cliquez sur réessayer. Si le problème persiste, vous recevrez une copie par courrier dans un délai de 7 à 10 jours ouvrables.",
                    dealerPrefix: "Détaillant:",
                    contactUs: "Pour une assistance supplémentaire ou pour demander qu’une copie vous soit envoyée par courrier électronique, veuillez contacter "
                }
            }
        }
    },
}