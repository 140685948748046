import React, { useEffect, useState, useContext } from 'react';
import { dataLayerPush } from '../../components/Global/helpers'
import { Container, Card, CardBody, Col } from 'reactstrap'
import classNames from "classnames";
import BrandedIcon from '../BrandingWhiteLabel/BrandedIcon';
import { SessionContext } from "../../context/SessionContext";
import { getFeatureFlags } from '../Global/helpers';


const CertificateOfCoverageError = ({ styles, accountName, handleLoadCertificate, handleTryAgain, expiredToken, languageCode }) => {
  const context = useContext(SessionContext);  
  const { translations, useTranslations, isTranslationsResolved } = context;
  useEffect(() => {
      dataLayerPush('Pageview', {
          pageTitle: "COC Error",
          pagePath: "/error/coc"
      })
  })

  useEffect(() => {    
    async function checkFlags() {
      var flags = await getFeatureFlags();
      let translationFlag = flags?.find(value => value.featureFlagName === "Lennox Translations")?.active ?? false;
      (languageCode !== "en" && translationFlag) && context.useTranslations("ErrorMessages", "fr-ca");
    }
    checkFlags();
  }, []);

  //SEU-7038: COC information implementation
  const cocInfoStatus = false;

  return (
    <Container className="contentWrapper">
      <Card>
        {/* SEU-7038: COC information implementation */}
        {expiredToken ? (
          <CardBody className="pb-8">
            <div className={styles.thanksConfirmation}>
              <BrandedIcon
                accountName={accountName}
                iconType={"email"}
              />
            </div>
            <Col md="12" lg="10" className={styles.invMessage}>
              <h1 className="h3 mb-4">{translations?.certificateOfCoverageExpiredError?.title || "The Certificate of Coverage link has expired."}</h1>
              <p className="pb-0 mb-0">
                {translations?.certificateOfCoverageExpiredError?.p1 || 
                  "We apologize for the inconvenience. You will receive a copy via mail within 7-10 business."
                }
              </p>
              <p className="pb-8">
                <strong>{translations?.certificateOfCoverageExpiredError?.dealerPrefix || "Dealers:"} </strong>
                {translations?.certificateOfCoverageExpiredError?.contactUs || 
                  "For additional support or to request a copy to be emailed, please contact"}
                <a className={classNames("btnLink", "mr-1")} href={`mailto:warranty-hvacsupport@aig.com`}> warranty-hvacsupport@aig.com</a>.
              </p>
            </Col>
          </CardBody>
        ) : (
            <CardBody className={classNames(styles.processingCert, "m-5")}>
              <BrandedIcon accountName={accountName} iconType={'email'} />
              <h1 className="h3 pt-5">{translations?.expeditedError?.title || "We are still processing this contract."}</h1>
              <p className="text-center">
                {translations?.expeditedError?.p1 || 
                  "Please wait a few minutes and click try again. If issue persists, you will receive a copy via mail within 7-10 business days."}
              </p>
              <div className={styles.dealersNote}>
                <p className="text-center">
                  <strong>{translations?.expeditedError?.dealerPrefix || "Dealers:"} </strong> 
                  {translations?.expeditedError?.contactUs || 
                    "For additional support or to request a copy be emailed, please contact"}
                    <a className={classNames("btnLink", "mr-1")} href={`mailto:hvacsupport@aig.com`}> hvacsupport@aig.com</a>.
                </p>
              </div>
              <button className="btn-primary" onClick={() => {
                handleTryAgain({ loadingPdf: true, showControlledError: false });
                handleLoadCertificate();
              }}>
                Try Again
              </button>
            </CardBody>
          )
        }
      </Card>
    </Container>
  )
}

export default CertificateOfCoverageError;