import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { ReactComponent as YesIcon } from '../../../../assets/images/checkmark.svg';
import styles from './PlanOptions.module.css';
import { Row } from "reactstrap";
import classnames from 'classnames';
import { getBrandContent } from '../../../BrandingWhiteLabel/BrandContent';
import { useContext } from "react";
import { SessionContext } from "../../../../context/SessionContext";

const coverageTypeTable = styles.coverageTypeTable;

const YesIconComp = () => {
  return (
    <div className={styles.yesIcon}><YesIcon /></div>
  )
};

const CoverageType = () => {

  const context = useContext(SessionContext)
  const { accountName } = context

  const coverageTypeColumnsRheem = [
    {
      dataField: "planBenefits",
      text: "Plan Benefits *"
    },
    {
      dataField: "laborOnly",
      text: "Labor Only"
    },
    {
      dataField: "laborPlus",
      text: "Labor PLUS"
    },
    {
      dataField: "partsLabor",
      text: "Parts"
    }
  ];

  const coverageTypeColumnsCarrier = [
    {
      dataField: "planBenefits",
      text: "Plan Benefits *"
    },
    {
      dataField: "laborPlus",
      text: "Labor PLUS"
    },
    {
      dataField: "laborOnly",
      text: "Labor Only"
    },
    {
      dataField: "partsLabor",
      text: "Parts"
    }
  ];

  const coverageTypeColumnsLennox = [
    {
      dataField: "planBenefits",
      text: "Plan Benefits *"
    },
    {
      dataField: "laborOnly",
      text: "Labor Only"
    },
    {
      dataField: "laborPlus",
      text: "Labor PLUS"
    },
    {
      dataField: "partsLabor",
      text: "Parts & Labor"
    }
  ];
  const coverageTypeDataLennox = [
    {
      planBenefits: "Tier Available On",
      laborOnly: "Basic Tier for Residential Products Only",
      laborPlus: "Tier 1, Tier 2, and Tier 3 for Residential Products Only",
      partsLabor: "Available for Select Products"
    },
    {
      planBenefits: "Trip Reimbursement",
      laborOnly: (<YesIconComp />),
      laborPlus: (<YesIconComp />),
      partsLabor: (<YesIconComp />)
    },
    {
      planBenefits: "Labor Costs",
      laborOnly: (<YesIconComp />),
      laborPlus: (<YesIconComp />),
      partsLabor: (<YesIconComp />)
    },
    {
      planBenefits: "Parts Markup",
      laborOnly: "",
      laborPlus: (<YesIconComp />),
      partsLabor: (<YesIconComp />)
    },
    {
      planBenefits: "Miscellaneous Parts",
      laborOnly: "",
      laborPlus: (<YesIconComp />),
      partsLabor: "Available with Tier 1, Tier 2, and Tier 3"
    },
    {
      planBenefits: "Out-of-Warranty Parts",
      laborOnly: "",
      laborPlus: "",
      partsLabor: (<YesIconComp />)
    },
    {
      planBenefits: "Refrigerant (Tier 1, Tier 2, and Tier 3)",
      laborOnly: "",
      laborPlus: (<YesIconComp />),
      partsLabor: (<YesIconComp />)
    }
  ];

  const coverageTypeDataRheem = [
    {
      planBenefits: "Plan Reimbursement",
      laborOnly: (<YesIconComp />),
      laborPlus: (<YesIconComp />),
      partsLabor: ""
    },
    {
      planBenefits: "Labor Costs",
      laborOnly: (<YesIconComp />),
      laborPlus: (<YesIconComp />),
      partsLabor: ""
    },
    {
      planBenefits: "Parts Allowance",
      laborOnly: "",
      laborPlus: (<YesIconComp />),
      partsLabor: ""
    },
    {
      planBenefits: "Out-of-Warranty Parts",
      laborOnly: "",
      laborPlus: "",
      partsLabor: (<YesIconComp />)
    },
    {
      planBenefits: "Refrigerant",
      laborOnly: (<YesIconComp />),
      laborPlus: (<YesIconComp />),
      partsLabor: ""
    }
  ];

  const coverageTypeDataCarrier = [
    {
      planBenefits: "Trip Reimbursement",
      laborPlus: (<YesIconComp />),
      laborOnly: (<YesIconComp />),
      partsLabor: ""
    },
    {
      planBenefits: "Labor Costs",
      laborPlus: (<YesIconComp />),
      laborOnly: (<YesIconComp />),
      partsLabor: ""
    },
    {
      planBenefits: "Parts Allowance",
      laborPlus: (<YesIconComp />),
      laborOnly: "",
      partsLabor: ""
    },
    {
      planBenefits: "Out-of-Warranty Parts",
      laborPlus: "",
      laborOnly: "",
      partsLabor: (<YesIconComp />)
    },
    {
      planBenefits: "Refrigerant",
      laborPlus: (<YesIconComp />),
      laborOnly: "",
      partsLabor: ""
    }
  ];

  const coverageTypeColumnsTrane = [
    {
      dataField: "planBenefits",
      text: "Plan Benefits *"
    },
    {
      dataField: "laborOnly",
      text: "Labor Only"
    },
    {
      dataField: "laborPlus",
      text: "Labor PLUS"
    },
    {
      dataField: "laborPlusParts",
      text: "Parts and Labor Plus"
    },
    {
      dataField: "partsLabor",
      text: "Parts Only"
    }
  ];

  const coverageTypeDataTrane = [
    {
      planBenefits: "Trip Reimbursment",
      laborOnly: (<YesIconComp />),
      laborPlus: (<YesIconComp />),
      laborPlusParts: (<YesIconComp />),
      partsLabor: ""
    },
    {
      planBenefits: "Labor Costs",
      laborOnly: (<YesIconComp />),
      laborPlus: (<YesIconComp />),
      laborPlusParts: (<YesIconComp />),
      partsLabor: ""
    },
    {
      planBenefits: "Parts Allowance",
      laborOnly: "",
      laborPlus: (<YesIconComp />),
      laborPlusParts: (<YesIconComp />),
      partsLabor: ""
    },
    {
      planBenefits: "Out-of-Warranty Parts",
      laborOnly: "",
      laborPlus: "",
      laborPlusParts: (<YesIconComp />),
      partsLabor: (<YesIconComp />)
    },
    {
      planBenefits: "Refrigerant",
      laborOnly: (<YesIconComp />),
      laborPlus: (<YesIconComp />),
      laborPlusParts: (<YesIconComp />),
      partsLabor: ""
    },
    {
      planBenefits: "Refrigerant (Value Plan)",
      laborOnly: "",
      laborPlus: (<YesIconComp />),
      laborPlusParts: (<YesIconComp />),
      partsLabor: ""
    }
  ];

  const coverageTypeColumnsGE = [
    {
      dataField: "planBenefits",
      text: "Plan Benefits *"
    },
    {
      dataField: "laborOnly",
      text: "Labor Only"
    },
    {
      dataField: "laborPlus",
      text: "Labor PLUS"
    },
    {
      dataField: "partsLabor",
      text: "Parts & Labor Plus"
    }
  ];

  const coverageTypeDataGE = [
    {
      planBenefits: "Trip/Diagnosis Reimbursement",
      laborOnly: (<YesIconComp />),
      laborPlus: (<YesIconComp />),
      partsLabor: (<YesIconComp />)
    },
    {
      planBenefits: "Labor Costs",
      laborOnly: (<YesIconComp />),
      laborPlus: (<YesIconComp />),
      partsLabor: (<YesIconComp />)
    },
    {
      planBenefits: "Parts (Out of OEM Warranty)",
      laborOnly: "",
      laborPlus: "",
      partsLabor: (<YesIconComp />)
    },
    {
      planBenefits: "Parts Markup",
      laborOnly: "",
      laborPlus: (<YesIconComp />),
      partsLabor: (<YesIconComp />)
    },
    {
      planBenefits: "Miscellaneous Parts",
      laborOnly: "",
      laborPlus: (<YesIconComp />),
      partsLabor: (<YesIconComp />)
    },
    {
      planBenefits: "Refrigerant",
      laborOnly: (<YesIconComp />),
      laborPlus: (<YesIconComp />),
      partsLabor: (<YesIconComp />)
    }
  ];

  const coverageTypeColumns = () => {
    switch (accountName.toLowerCase()) {
      case "lennox": return coverageTypeColumnsLennox;
      case "rheem": return coverageTypeColumnsRheem;
      case "carrier": return coverageTypeColumnsCarrier;
      case "trane": return coverageTypeColumnsTrane;
      case "ge": return coverageTypeColumnsGE;
      default:
        console.log("Developer Error: account name has no associated coverage type columns")
    }
  }

  const coverageTypeData = () => {
    switch (accountName.toLowerCase()) {
      case "lennox": return coverageTypeDataLennox;
      case "rheem": return coverageTypeDataRheem;
      case "carrier": return coverageTypeDataCarrier;
      case "trane": return coverageTypeDataTrane;
      case "ge": return coverageTypeDataGE;
      default:
        console.log("Developer Error: account name has no associated coverage type columns")
    }
  }

  return (
    <>
      {(accountName.toLowerCase() === "ge") 
        ? <p>Plan options will vary depending on the product and {getBrandContent(accountName).pricingLevelName.toLowerCase()} selected.</p>
        : <p>Plan Options will vary depending on the product and {getBrandContent(accountName).pricingLevelName.toLowerCase()} selected.</p>
      }
      
      <div className={classnames(coverageTypeTable, "m-lg-0")}>
        <BootstrapTable
          keyField="planBenefits"
          data={coverageTypeData()}
          columns={coverageTypeColumns()}
          bordered={true}
          bootstrap4
          wrapperClasses="table-responsive-lg"
        />
      </div>
      {accountName.toLowerCase() === "lennox" ? (
        <p className="mt-3 mb-0">
          *Depending on the plan purchased, benefits will vary. Limitations and
          exclusions apply. Please see the full terms and conditions for further
          details.
        </p>
      ) : (
          <p className="mt-3 mb-0">
            *Depending on the plan purchased, benefits will vary. Limitations and
            exclusions apply. Please see the full terms and conditions for further
            details.
        </p>
        )}
    </>
  );
}

export default CoverageType