import React, { useState, useEffect, useRef, useContext } from "react";
import { Row, Col, Label, Input, FormGroup } from "reactstrap";
import classNames from "classnames";
import styles from "./Login.module.css";
import SimpleReactValidator from "simple-react-validator";
import Cookies from "js-cookie";
import { SessionContext } from "../context/SessionContext";
import EnrollmentModal from "../components/Modals/Enrollment/Enrollment";
import { useHistory } from 'react-router-dom';
import { AuthErrorCodes } from '../components/Global/AuthConstants';
import { getBrandContent } from "../components/BrandingWhiteLabel/BrandContent";
import { stripPhoneNumber } from '../lib/util';


const LoginForm = () => {
    const [formData, setFormData] = useState({
        dealerid: "",
        password: "",
        rememberMe: false,
    });
    const [validationCheck, setValidationCheck] = useState(false);
    const [loginAttemptFailed, setLoginAttemptFailed] = useState(false);
    const [, forceUpdate] = useState();
    const [loginFailedCount, setLoginFailedCount] = useState(1);
    const [showEnrollmentModal, setShowEnrollmentModal] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [accountInactive, setAccountInactive] = useState(false);
    const [loginError, setLoginError] = useState(false);
    const history = useHistory();


    const simpleValidator = useRef(
        new SimpleReactValidator({
            element: (message) => (
                <div className="errorMsg">
                    {message.charAt(0).toUpperCase() + message.slice(1)}
                </div>
            ),
            messages: {
                required: ":attribute is required.",
            },
        }),
    );


    var sessionContext = useContext(SessionContext);

    useEffect(() => {
        updateRememberMeFields();
        (sessionContext.errorCode === AuthErrorCodes.dealerNotFound || sessionContext.errorCode === AuthErrorCodes.inactiveDealer)
            && setAccountInactive(true);
        sessionContext.errorCode === AuthErrorCodes.systemError && setLoginError(true);
    }, []);

    const handleInputChange = (e) => {
        let { name, value } = e.target;

        if (name === "rememberMe") {
            setFormData(
                {
                    ...formData,
                    rememberMe: !formData.rememberMe,
                },
                updateRememberMeCookies(!formData.rememberMe),
            );
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleLogin = async (e) => {
        e.preventDefault();

        setDisableButton(true);

        if (simpleValidator.current.allValid()) {
            setValidationCheck(false);
            /* todo service call*/
            var ret = await authenticateUser();
            /* Need to wire-up the error messages when linking to the vendor portal from the Rheem dealer site:
                1. If dealer account is not found or dealer account is inactive.
                2. If there is a system error in processing the SSO.
            */
            setAccountInactive(false);
            setLoginError(false);
            if (ret == false) {
                setLoginAttemptFailed(true);
                setLoginFailedCount(loginFailedCount + 1);

                if (loginFailedCount > 3) {
                    sessionContext.setLoginExceeded(true);
                    history.push("/");
                }
            } else {
                setLoginAttemptFailed(false);
                updateRememberMeFields();
                window.location.replace(window.location.origin);
                sessionContext.setIsResetPasswordSuccess(false);
            }
        } else {
            setValidationCheck(true);
            simpleValidator.current.showMessages();
            forceUpdate(1);
        }

        setDisableButton(false);
    };

    const authenticateUser = async () => {
        var model = {
            DealerId: formData.dealerid,
            Password: formData.password,
            RememberMe: formData.rememberMe,
        };

        var response = await fetch("authentication/authenticateuser/", {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(model),
        });

        if (response.ok) {
            const result = await response.json();
            if (result.succeeded == true) {
                return true;
            }
        }
        return false;
    };

    const updateRememberMeFields = () => {
        var dealerId = Cookies.get("dealerid");
        var rememberMe = Cookies.get("rememberMe");
        if (rememberMe && dealerId) {
            setFormData({
                ...formData,
                dealerid: dealerId,
                rememberMe: rememberMe === "true",
            });
        }
    };

    const updateRememberMeCookies = (rememberMe) => {
        if (rememberMe) {
            Cookies.set("dealerid", formData.dealerid, { expires: 365 });
            Cookies.set("rememberMe", true, { expires: 365 });
        } else {
            Cookies.remove("dealerid");
            Cookies.remove("rememberMe");
        }
    };

    const handleShowActionModal = () => {
        setShowEnrollmentModal(true);
    };

    const { accountName } = sessionContext;
    const accountNameToLower = accountName.toLowerCase();

    return (
        <>
            <h1 className="h3">Welcome</h1>
            <FormGroup className={!simpleValidator.current.check(formData.dealerid, "required") && validationCheck ? "form-error" : ""} >
                <label for="dealerid" id="dealerid" className="form-label">
                    Dealer ID
                </label>
                <span aria-hidden="true" className="required-indicator"> *</span>
                <Input
                    name="dealerid"
                    aria-labelledby="dealerid"
                    type="text"
                    className={classNames("form-control", accountNameToLower === "ge" && styles.inputGE)}
                    defaultValue={formData.dealerid}
                    onChange={handleInputChange}
                />
                {simpleValidator.current.message(
                    "dealerId",
                    formData.dealerid,
                    "required",
                    {
                        messages: {
                            required: "Dealer ID is required."
                        }
                    }
                )}
            </FormGroup>
            <FormGroup
                className={!simpleValidator.current.check(formData.password, "required") && validationCheck ? "form-error mb-0" : "mb-0"}>
                <label for="password" id="password" className="form-label">
                    Password
                </label>
                <span aria-hidden="true" className="required-indicator">*</span>
                <Input
                    name="password"
                    aria-labelledby="password"
                    type="password"
                    className={classNames("form-control", accountNameToLower === "ge" && styles.inputGE)}
                    defaultValue={formData.password}
                    onChange={handleInputChange}
                />
                {simpleValidator.current.message("password", formData.password, "required")}
            </FormGroup>
            <Row>
                <div className="checkbox mt-2 col-6">
                    <Input
                        className="mr-2"
                        type="checkbox"
                        name="rememberMe"
                        checked={formData.rememberMe}
                        autocomplete="current-password"
                        onChange={handleInputChange}
                        id="rememberMe"
                    />
                    <Label for="rememberMe" className={`checkboxLabel ${sessionContext.accountName}`}>
                        Remember Me
                    </Label>
                </div>
                <div className={classNames("mt-2 col-6", styles.alignRight)}>
                    <a href="/forgotpassword">Forgot Password?</a>
                </div>
            </Row>
            <div className={styles.clear}></div>
            {accountInactive && (
            <div className={`${styles.summary} validationSummary`}>
                We were unable to log you in with single sign-on.&nbsp;<a onClick={handleShowActionModal}>Enroll now</a> to join {getBrandContent(accountName).programName} or contact support at <a href={`tel:${stripPhoneNumber(getBrandContent(accountName).programPhone)}`} aria-label="Click to Call" className={`${styles.visibleOne} callLink`}>{getBrandContent(accountName).programPhone}</a>.
            </div>
            )}
            {loginError && (
            <div className="validationSummary">
                We were unable to log you in with single sign-on. Please login below or <a href="/forgotpassword"> create a password</a> for direct login access.
            </div>
            )}
            {loginAttemptFailed && (
                <div className="validationSummary mb-3">
                    The login you have entered does not match our records.
                </div>
            )}
            <div className={styles.loginBtnWrapper}>
                <button
                    className={classNames(accountNameToLower === "ge" ? styles.loginBtnGE : "btn-primary")}
                    onClick={handleLogin}
                    disabled={disableButton}>
                    Login
                </button>
            </div>
            <Col md="12" className={styles.enrollNow}>
                Don't have an account?
                {showEnrollmentModal && (
                    <EnrollmentModal
                        isOpen={showEnrollmentModal}
                        centered={true}
                        toggle={() => setShowEnrollmentModal(!showEnrollmentModal)}
                        accountName={accountName}
                    />
                )}
                <a
                    onClick={(e) => handleShowActionModal()}
                    className={classNames("btn-link", styles.enrollmentLink)}
                    title="Click to open a modal to enroll">
                    <b>Enroll Now</b>
                </a>
            </Col>
        </>
    );
};

export default LoginForm;
