import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import classnames from 'classnames';
import styles from './PlanOptions.module.css';
import { useContext } from "react";
import { SessionContext } from "../../../../context/SessionContext";
import { getBrandContent } from '../../../BrandingWhiteLabel/BrandContent';

const ReimbursementRates = () => {

  const context = useContext(SessionContext)
  const { accountName } = context
  const currentBrand = getBrandContent(accountName);
  const isCarrier = currentBrand.clientName.toLowerCase() === 'carrier';
  const isRheem = currentBrand.clientName.toLowerCase() === 'rheem';
  const isTrane = currentBrand.clientName.toLowerCase() === 'trane';
  const isGE = currentBrand.clientName.toLowerCase() === 'ge';

  const reimbursementColumnsLennox = [
    {
      dataField: "reimbursementType",
      text: ""
    },
    {
      dataField: "perRepairHr",
      text: "Per Repair Hour"
    },
    {
      dataField: "tripAllowance",
      text: "Trip Allowance"
    },
  ];

  const reimbursementDataLennox = [
    {
      reimbursementType: "Basic",
      perRepairHr: "$85",
      tripAllowance: "$65"
    },
    {
      reimbursementType: "Tier 1",
      perRepairHr: "$125",
      tripAllowance: "$95"
    },
    {
      reimbursementType: "Tier 2",
      perRepairHr: "$175",
      tripAllowance: "$100"
    },
    {
      reimbursementType: "Tier 3",
      perRepairHr: "$250",
      tripAllowance: "$125"
    }
  ];

  const reimbursementColumns = [
    {
      dataField: "reimbursementPlanType",
      text: "Plan"
    },
    {
      dataField: "plan",
      text: "Plan/Diagnostic"
    },
    {
      dataField: "laborRate",
      text: "Labor Rate"
    },
    {
      dataField: "range",
      text: (<div>Range of Reimbursement<br /> (Based on Repair Code)</div>)
    }
  ];

  const reimbursementData = [
    {
      reimbursementPlanType: "Premium Plan 1",
      plan: "$50",
      laborRate: "$70",
      range: "$128.75  – $435.00"
    },
    {
      reimbursementPlanType: "Premium Plan 2",
      plan: "$85",
      laborRate: "$85",
      range: "$180.63  – $552.50"
    },
    {
      reimbursementPlanType: "Core Plan 2",
      plan: "$65",
      laborRate: "$85",
      range: "$42.50 – $410.00"
    },
    {
      reimbursementPlanType: "Premium Plan 3",
      plan: "$95",
      laborRate: "$125",
      range: "$235.63 – $782.50"
    },
    {
      reimbursementPlanType: "Core Plan 3",
      plan: "$65",
      laborRate: "$125",
      range: "$62.50 – $570.00"
    }
  ];

  const reimbursementColumnsCareer = [
    {
      dataField: "reimbursementCoverage",
      text: "Coverage"
    },
    {
      dataField: "reimbursementPlanType",
      text: "Plan"
    },
    {
      dataField: "trip",
      text: "Trip/Diagnostic"
    },
    {
      dataField: "laborRate",
      text: "Labor Rate"
    },
    {
      dataField: "range",
      text: (<div>Range of Reimbursement<br />(Based on Repair Code/Hours)</div>)
    }
  ];

  const reimbursementDataCareer = [
    {
      reimbursementCoverage: "Labor Only",
      reimbursementPlanType: currentBrand.standardPlanOne,
      trip: "$65",
      laborRate: "$85",
      range: "$161.05 - $532.50"
    },
    {
      reimbursementCoverage: "Labor Plus",
      reimbursementPlanType: currentBrand.standardPlanOne,
      trip: "$65",
      laborRate: "$85",
      range: "$196.05 - $567.50"
    },
    {
      reimbursementCoverage: "Labor Only",
      reimbursementPlanType: currentBrand.valuePlanOne,
      trip: "$65",
      laborRate: "$85",
      range: "$107.50 - $405.00"
    },
    {
      reimbursementCoverage: "Labor Plus",
      reimbursementPlanType: currentBrand.valuePlanOne,
      trip: "$65",
      laborRate: "$85",
      range: "$132.50 - $475.00"
    },
    {
      reimbursementCoverage: "Labor Only",
      reimbursementPlanType: currentBrand.standardPlanTwo,
      trip: "$95",
      laborRate: "$125",
      range: "$236.25 - $782.50"
    },
    {
      reimbursementCoverage: "Labor Plus",
      reimbursementPlanType: currentBrand.standardPlanTwo,
      trip: "$95",
      laborRate: "$125",
      range: "$271.25 - $817.50"
    },
    {
      reimbursementCoverage: "Labor Only",
      reimbursementPlanType: currentBrand.valuePlanTwo,
      trip: "$65",
      laborRate: "$125",
      range: "$127.50 - $565.00"
    },
    {
      reimbursementCoverage: "Labor Plus",
      reimbursementPlanType: currentBrand.valuePlanTwo,
      trip: "$65",
      laborRate: "$125",
      range: "$152.50 - $635.00"
    },
    {
      reimbursementCoverage: "Labor Only",
      reimbursementPlanType: currentBrand.standardPlanThree,
      trip: "$95",
      laborRate: "$150",
      range: "$264.50 - $920.00"
    },
    {
      reimbursementCoverage: "Labor Plus",
      reimbursementPlanType: currentBrand.standardPlanThree,
      trip: "$95",
      laborRate: "$150",
      range: "$299.50 - $955.00"
    },
    {
      reimbursementCoverage: "Labor Only",
      reimbursementPlanType: currentBrand.standardPlanFour,
      trip: "$95",
      laborRate: "$200",
      range: "$321.00 - $1,195.00"
    },
    {
      reimbursementCoverage: "Labor Plus",
      reimbursementPlanType: currentBrand.standardPlanFour,
      trip: "$95",
      laborRate: "$200",
      range: "$365.00 - $1,230.00"
    },
    {
      reimbursementCoverage: "Labor Only",
      reimbursementPlanType: currentBrand.standardPlanFive,
      trip: "$95",
      laborRate: "$250",
      range: "$376.25 - $1,470.00"
    },
    {
      reimbursementCoverage: "Labor Plus",
      reimbursementPlanType: currentBrand.standardPlanFive,
      trip: "$95",
      laborRate: "$250",
      range: "$411.25 - $1,505.00"
    },
    {
      reimbursementCoverage: "Labor Only",
      reimbursementPlanType: currentBrand.standardPlanSix,
      trip: "$95",
      laborRate: "$300",
      range: "$432.50 - $1,745.00"
    },
    {
      reimbursementCoverage: "Labor Plus",
      reimbursementPlanType: currentBrand.standardPlanSix,
      trip: "$95",
      laborRate: "$300",
      range: "$467.50 - $1,780.00"
    }
  ];

  const reimbursementColumnsTraneLaborPlus = [
    {
      dataField: "reimbursementPlanType",
      text: "Plan"
    },
    {
      dataField: "trip",
      text: "Trip/Diagnostic"
    },
    {
      dataField: "laborRate",
      text: "Labor Rate"
    },
    {
      dataField: "partsAllowance",
      text: "Parts Allowance"
    },
    {
      dataField: "range",
      text: (<div>Range of Reimbursement<br /> (Based on Repair Code)</div>)
    }
  ];

  const reimbursementDataTraneLaborPlus = [
    {
      reimbursementPlanType: currentBrand.standardPlanOne,
      trip: "$40",
      laborRate: "$70",
      partsAllowance: "$35",
      range: "$153.75 - $460.00"
    },
    {
      reimbursementPlanType: currentBrand.standardPlanTwo,
      trip: "$65",
      laborRate: "$85",
      partsAllowance: "$35",
      range: "$195.63 - $567.50"
    },
    {
      reimbursementPlanType: currentBrand.valuePlanTwo,
      trip: "$65",
      laborRate: "$85",
      partsAllowance: "$25",
      range: "$132.50 - $555.00"
    },
    {
      reimbursementPlanType: currentBrand.standardPlanThree,
      trip: "$85",
      laborRate: "$100",
      partsAllowance: "$35",
      range: "$232.50 - $670.00"
    },
    {
      reimbursementPlanType: currentBrand.standardPlanFour,
      trip: "$95",
      laborRate: "$125",
      partsAllowance: "$35",
      range: "$270.63 - $817.50"
    },
    {
      reimbursementPlanType: currentBrand.valuePlanFour,
      trip: "$65",
      laborRate: "$125",
      partsAllowance: "$25",
      range: "$152.50 - $715.00"
    },
    {
      reimbursementPlanType: currentBrand.standardPlanFive,
      trip: "$95",
      laborRate: "$150",
      partsAllowance: "$35",
      range: "$298.75 - $955.00"
    },
    {
      reimbursementPlanType: currentBrand.standardPlanSix,
      trip: "$95",
      laborRate: "$200",
      partsAllowance: "$35",
      range: "$355 - $1230.00"
    },
  ];

  const reimbursementColumnsTraneLaborOnly = [
    {
      dataField: "reimbursementPlanType",
      text: "Plan"
    },
    {
      dataField: "trip",
      text: "Trip/Diagnostic"
    },
    {
      dataField: "laborRate",
      text: "Labor Rate"
    },
    {
      dataField: "partsAllowance",
      text: "Parts Allowance"
    },
    {
      dataField: "range",
      text: (<div>Range of Reimbursement<br /> (Based on Repair Code)</div>)
    }
  ];

  const reimbursementDataTraneLaborOnly = [
    {
      reimbursementPlanType: currentBrand.standardPlanOne,
      trip: "$40",
      laborRate: "$70",
      partsAllowance: "$0",
      range: "$118.75 - $425.00"
    },
    {
      reimbursementPlanType: currentBrand.standardPlanTwo,
      trip: "$65",
      laborRate: "$85",
      partsAllowance: "$0",
      range: "$180.63 - $532.50"
    },
    {
      reimbursementPlanType: currentBrand.valuePlanTwo,
      trip: "$65",
      laborRate: "$85",
      partsAllowance: "$0",
      range: "$107.50 - $405.00"
    },
    {
      reimbursementPlanType: currentBrand.standardPlanThree,
      trip: "$85",
      laborRate: "$100",
      partsAllowance: "$0",
      range: "$197.50 - $635.00"
    },
    {
      reimbursementPlanType: currentBrand.standardPlanFour,
      trip: "$95",
      laborRate: "$125",
      partsAllowance: "$0",
      range: "$235.63 - $782.50"
    },
    {
      reimbursementPlanType: currentBrand.valuePlanFour,
      trip: "$65",
      laborRate: "$125",
      partsAllowance: "$0",
      range: "$127.50 - $565.00"
    },
    {
      reimbursementPlanType: currentBrand.standardPlanFive,
      trip: "$95",
      laborRate: "$150",
      partsAllowance: "$0",
      range: "$263.75 - $920.00"
    },
    {
      reimbursementPlanType: currentBrand.standardPlanSix,
      trip: "$95",
      laborRate: "$200",
      partsAllowance: "$0",
      range: "$320.00 - $1195.00"
    },
  ];

  const reimbursementColumnsGE = [
    {
      dataField: "reimbursementPlanType",
      text: "Plans"
    },
    {
      dataField: "trip",
      text: "Trip"
    },
    {
      dataField: "laborRate",
      text: "Labor"
    }
  ];

  const reimbursementDataGE = [
    {
      reimbursementPlanType: currentBrand.standardPlanOne,
      trip: "$65",
      laborRate: "$85"
    },
    {
      reimbursementPlanType: currentBrand.standardPlanTwo,
      trip: "$85",
      laborRate: "$125"
    },
    {
      reimbursementPlanType: currentBrand.standardPlanThree,
      trip: "$85",
      laborRate: "$200"
    }
  ];

  return (
    <>
      <p>
        We offer multiple reimbursement options to meet your business needs.
      </p>
      {currentBrand.clientName.toLowerCase() === "lennox" && (
        <Row>
          <Col xs="12" lg="6">
            <div className={styles.reimbursementRatesTable}>
              <BootstrapTable
                keyField="reimbursementType"
                data={reimbursementDataLennox}
                columns={reimbursementColumnsLennox}
                bordered={true}
                bootstrap4
                wrapperClasses="table-responsive"
              />
            </div>
          </Col>
          <Col xs="12" lg="6">
            <ul className={styles.reimbursementBullets}>
              <li className="mb-3">
                Up to <b>40%</b> parts markup under <b>Tier 1</b>, <b>Tier 2,</b> and <b>Tier 3</b>.
              </li>
              <li>
                Refrigerant Coverage Provided Under <b>Tier 1</b>, <b>Tier 2,</b> and <b>Tier 3</b>, 
                up to <b>50%</b> markup if included in plan.
              </li>
            </ul>
          </Col>
        </Row>
      )}
      {isRheem && (
        <div className={classnames(styles.reimbursementRatesTable, styles.reimbursementRatesTableRheem)}>
          <BootstrapTable
            keyField="reimbursementType"
            data={reimbursementData}
            columns={reimbursementColumns}
            bordered={true}
            bootstrap4
            wrapperClasses="table-responsive"
          />
        </div>
      )}
      {isCarrier && (
        <div className={classnames(styles.reimbursementRatesTable, styles.reimbursementRatesTableCarrier)}>
          <BootstrapTable
            keyField="reimbursementType"
            data={reimbursementDataCareer}
            columns={reimbursementColumnsCareer}
            bordered={true}
            bootstrap4
            wrapperClasses="table-responsive"
          />
        </div>
      )}
      {isTrane && (
        <>
          <div className={classnames(styles.reimbursementRatesTable, styles.reimbursementRatesTableCarrier)}>
            <div className={styles.traneTableHead}>
              <h1 className='h3'>Labor Plus</h1>
            </div>
            <BootstrapTable
              keyField="reimbursementType"
              data={reimbursementDataTraneLaborPlus}
              columns={reimbursementColumnsTraneLaborPlus}
              bordered={true}
              bootstrap4
              wrapperClasses="table-responsive"
            />
          </div>
          <div className={classnames(styles.reimbursementRatesTable, styles.reimbursementRatesTableCarrier, 'mt-4')}>
            <div className={styles.traneTableHead}>
              <h1 className='h3'>Labor Only</h1>
            </div>
            <BootstrapTable
              keyField="reimbursementType"
              data={reimbursementDataTraneLaborOnly}
              columns={reimbursementColumnsTraneLaborOnly}
              bordered={true}
              bootstrap4
              wrapperClasses="table-responsive"
            />
          </div>
        </>
      )}
      {isGE && (
        <div className={classnames(styles.reimbursementRatesTable, styles.reimbursementRatesTableGE)}>
          <BootstrapTable
            keyField="reimbursementType"
            data={reimbursementDataGE}
            columns={reimbursementColumnsGE}
            bordered={true}
            bootstrap4
            wrapperClasses="table-responsive"
          />
        </div>
      )}
    </>
  );
}

export default ReimbursementRates
