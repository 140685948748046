import React, { Component } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

export class TextInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            placeHolder: props.placeHolder
        };
    }

    render() {
        return (
            <div>
                <Input type="text"
                    placeholder={this.state.placeHolder}
                    value={this.props.value}
                    onChange={this.props.onChangedValue}
                    name={this.props.name}
                />
            </div>
        );
    }
}