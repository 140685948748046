import React, { forwardRef } from 'react';
import TermsAndConditions from '../shared/TermsAndConditions';


const TermsAndCondition = forwardRef((props, ref) => {
    const { validator, errors,document } = props;

    return (
        <TermsAndConditions validator={validator} errors={errors}  fileUrl={(document)}/>
    );
});

export default TermsAndCondition;