import React, { useState, forwardRef, useEffect, useContext, useImperativeHandle } from 'react';
import { CardBody, Row, Col, FormGroup } from 'reactstrap';
import StandardInput from "./StandardInput/StandardInput";
import { getBrandContent } from "../BrandingWhiteLabel/BrandContent";
import { SessionContext } from "../../context/SessionContext";
import CheckboxInput from './CheckboxInput/CheckboxInput';
import { Viewer, Worker, SpecialZoomLevel } from '@react-pdf-viewer/core';
import styles from './TermsAndConditions.module.css';
import DownloadButton from "../shared/IconButtons/DownloadButton";


const TermsAndCondition = forwardRef((props, ref) => {
    const { validator, errors, fileUrl ,checkDisable } = props;
    const [forceUpdate, setForceUpdate] = useState(0);
    const [formData, setFormData] = useState({});
    const [validationFired, setValidationFired] = useState(false);
    const { accountName } = useContext(SessionContext);
    const programName = getBrandContent(accountName).programName;


    useEffect(() => {
        if (Object.keys(errors).length > 0) {
            validator.showMessages();
            setValidationFired(true);
            setForceUpdate(1);
        }
        if(checkDisable) checkDisable();
    }, [errors, validator, formData]);

    useImperativeHandle(ref, () => ({
        getFormValues() {
            return formData;
        }
    }));

    const handleCheckBoxChange = (e) => {
        const { id } = e.target;
        setFormData({
            ...formData,
            [id]: e.target.checked,
        });
        if(checkDisable) checkDisable();
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        if(checkDisable) checkDisable();
    };


    return (
        <>
            <Col xs="12" md="12" className={`titleRow p-4`}>
                <h2 className="h4 m-0">{programName} Enrollment Terms and Conditions</h2>
            </Col>
            <hr className="m-0 w-100" />
            <CardBody>
               {fileUrl && <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.5.207/build/pdf.worker.js">
                    <div className={`mb-4 overflow-scroll`}>
                        <Viewer 
                            fileUrl={fileUrl}
                            plugins={[]}
                            defaultScale={SpecialZoomLevel.PageWidth}
                        />
                    </div>
                </Worker>}
                <Row className="m-0 d-flex flex-column">
                    <div>
                        <CheckboxInput
                            id="termsAgreementChecked"
                            customClass='mr-2 mb-2'
                            checked={formData.termsAgreementChecked}
                            handleCheck={handleCheckBoxChange}
                            clientId={accountName}
                        >
                            I agree with the Terms and Conditions
                            <span aria-hidden="true" className="required-indicator">*</span>
                        </CheckboxInput>
                    </div>
                    <div className='errorMsg mb-2'>
                        {validator.message("termsAgreement", formData.termsAgreementChecked, "required", { messages: { required: "Please accept the terms and conditions before proceeding." } })}
                    </div>
                </Row>
                <Row className="m-0 d-flex flex-column">
                    <CheckboxInput
                        id="privacyAgreementChecked"
                        customClass='mr-2 mb-2'
                        checked={formData.privacyAgreementChecked}
                        clientId={accountName}
                        handleCheck={handleCheckBoxChange}
                    >
                        I agree with the <a className="ml-1" href="https://www.aig.com/privacy-policy" target="_blank" rel="noopener noreferrer"> Privacy Policy</a>
                        <span aria-hidden="true" className="required-indicator">*</span>
                    </CheckboxInput>
                    <div className='errorMsg mb-2'>
                        {validator.message("privacyAgreement", formData.privacyAgreementChecked, "required", { messages: { required: "Please accept the privacy agreement before proceeding." } })}
                    </div>
                </Row>
                <Row>
                    <Col md="4" xl="3">
                        <FormGroup >
                            <StandardInput
                                hasError={validationFired && !validator.check(formData.eSignDocument, "required")}
                                ariaLabelAndFieldId="eSignDocument"
                                labelCustomClass="font-weight-bold"
                                label="eSign Document"
                                fieldName="eSignDocument"
                                value={formData.eSignDocument}
                                onChange={handleInputChange}
                                placeholder="Type full name to sign"
                                validator={validator.message("esign", formData.eSignDocument, "required", { messages: { required: "Please enter your full name to sign the agreement electronically." } })}
                            />
                        </FormGroup>                    
                    </Col>
                    {fileUrl !== undefined ?
                        <Col md="8" xl="9">
                            <div className="d-flex justify-content-end mt-4 pt-2">
                                <DownloadButton id="downloadTerms" onClick={() => window.open(fileUrl, '_blank')} className={styles.saveBtn} />
                            </div>
                        </Col>
                        :
                        ""
                    }
                </Row>
            </CardBody>
        </>
    );
});

export default TermsAndCondition;