import React, { Component, Fragment, createRef } from "react";
import { Container, Card } from "reactstrap";
import DownloadButton from "../shared/IconButtons/DownloadButton";
import '@react-pdf-viewer/core/lib/styles/index.css';
import styles from './DealerTerms.module.css';
import moment from 'moment';
import { SessionContext } from "../../context/SessionContext";
import { EnrollmentForm } from '../EnrollmentForm/EnrollmentForm';
import { pdfjs } from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { dataLayerPush } from '../../components/Global/helpers';
import { getDealerDocument, accountDocumentTypes } from '../../components/Global/DocumentHelper';
import TermsAndConditions from '../shared/TermsAndConditions';
import SimpleReactValidator from "simple-react-validator";


pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export default class DealerTerms extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errors: {},
            isEndSession: false,            
            dealerAgreementAcceptanceModel: {
                dealerAgreementAcceptanceId: '',
                dealerId: '',
                needsToReacceptFlag: false,
                createdDate: '',
                createdBy: '',
                lastUpdatedDate: '',
                lastUpdatedBy: '',
                modifiedBy: '',
                modifiedDate: '',
                acceptedBy: ''
            },
            isCanadaDealer: props?.profileModel?.country == "Canada" ? true : false,
            pdfLocation: '',
            profileModel: {},
            disableSave: true
        }

        this.save = this.save.bind(this);
        this.validator = new SimpleReactValidator();
        this.childRef = createRef()

    }

    componentDidMount() {
        var profileModel = this.context.getProfile()
        this.setState({ profileModel });

        dataLayerPush('Pageview', {
            pagePath: '/tscs',
            pageTitle: 'Enrollment TsCs'
        })

        getDealerDocument(accountDocumentTypes.TermsAndConditions, false, profileModel, this.getTCDoc);
    }

    save = () => {
        if (!this.validator.allValid()) {
            this.setState({ errors: this.validator.errorMessages });
            return
        }
        this.validator.hideMessages();
        this.setState({ errors: {} });
        const formData = this.childRef?.current?.getFormValues() ?? {};

        try {
            var profile = this.context.getProfile();
            var terms = profile.dealerTerms;
            var newTerms = '';

            if (terms != null) {
                newTerms = JSON.stringify({
                    dealerAgreementAcceptanceId: terms.dealerAgreementAcceptanceID,
                    dealerId: terms.dealerId,
                    needsToReacceptFlag: false,
                    createdBy: terms.createdBy,
                    createdDate: terms.createdDate,
                    modifiedBy: 'VendorPortal',
                    modifiedDate: moment(),
                    acceptedBy: formData.eSignDocument
                });
            }
            else {
                newTerms = JSON.stringify({
                    dealerId: profile.dealerId,
                    needsToReacceptFlag: false,
                    createdBy: 'VendorPortal',
                    createdDate: moment(),
                    acceptedBy: formData.eSignDocument
                });
            }
            this.setState({ dealerAgreementAcceptanceModel: newTerms, isEndSession: true });
        }
        catch (error) { }
    }

    getTCDoc = (res) => {
        this.setState({ pdfLocation: res.documentPath });
    }

    checkDisable = () => {
        this.setState({ disableSave: !this.validator.allValid() });
    }

   
    render() {
        
        let fileUrl = this.state.pdfLocation;
        return (
            <Fragment>
                {!this.state.isEndSession &&
                    <Container className="contentWrapper">
                        
                        <Card>
                        <TermsAndConditions
                            fileUrl={fileUrl}
                            validator={this.validator}
                            errors={this.state.errors}
                            checkDisable={this.checkDisable}
                            ref={this.childRef} />
                        </Card>
                        <div className="d-flex justify-content-end">
                        <button className="btn-primary" disabled={this.state.disableSave} onClick={this.save}>Accept</button>
                        </div>
                    </Container>
                }
                {
                    this.state.isEndSession &&
                    < EnrollmentForm profileModel={this.context.getProfile()} termsModel={this.state.dealerAgreementAcceptanceModel} />
                }
            </Fragment>
        );
    }
}
DealerTerms.contextType = SessionContext;