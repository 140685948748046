import React, { useContext, useState } from 'react';
import { AdjudicationContext } from './store/context';
import { ReactComponent as TrashIcon } from '../../../assets/images/trash.svg';
import styles from '../Adjudication.module.css'
import { DropDownQueue } from '../../inputs/DropDownQueue';
import NumberFormat from 'react-number-format';
import MultiDropDown from '../../shared/MultiDropDown/MultiDropDown'
import { newEntry } from './util'
import { ReactComponent as Plus } from '../../../assets/images/plus.svg'
import KeyboardEventHandler from 'react-keyboard-event-handler';
import InfoTooltip from "../../shared/InfoTooltip/InfoTooltip";
import { DropDownAutoComplete } from '../../inputs/DropDownAutoComplete';

export const CaptionComponent = ({ title, preapprovedAmt }) => {
    const currencyFormat = preapprovedAmt && new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(preapprovedAmt)
    return (
        <div className={`${styles.sectionHeaderRow} ${preapprovedAmt && styles.topTable}`}>
            <p>{title}</p>
            {preapprovedAmt ?
                <p>
                    {`Pre-Authorized: ${currencyFormat}`}
                </p>
                :
                ""
            }
        </div>
    )
}

export const HeaderFormatter = ({ col, centered = false, toolTipRequired = false, toolTipMessage = "" }) => {
    return (
        <div style={{
            textAlign: 'center',
            height: '36px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: centered ? 'center' : 'flex-start',
            gap: '0.5ch'
        }}>
            {col.text}

            {toolTipRequired ? (<span aria-hidden="true"><InfoTooltip iconType="info" message={toolTipMessage} /></span>) : ''}
        </div>
    );
}


export const widthFormatter = (width) => {
    const widths = {
        dropdownCol: '150px',
        rateTypeCol: '225px',
        tripCol: '180px',
        qtyCol: '50px',
        amountCol: '100px',
        deleteCol: '36px',
        partDescription: '270px',
        mileageDescription: '270px',
        tripType: '225px'
    }

    return {
        width: widths[width],
    }
};

//general use in all tables
export const DeleteRowButton = ({ rowId, rowIndex, table }) => {
    const { state } = useContext(AdjudicationContext)
    const hide = (state.isTableReadOnly || (table === 'labor' && rowIndex === 0))
    const { dispatch } = useContext(AdjudicationContext)
    return (!hide &&
        <TrashIcon className={styles.trashIcon}
            onClick={() => {
                dispatch({
                    type: 'REMOVE_ROW',
                    payload: {
                        table,
                        rowId,
                    }
                })
            }}
        />
    )
}



export const AddRowButton = ({ table }) => {
    const { state, dispatch } = useContext(AdjudicationContext);
    const getName = (table) => {
        const names = {
            mileage: 'Mileage',
            parts: 'Part',
            labor: 'Labor Rate'
        }
        return names[table]
    }

    if (state.isTableReadOnly) return null
    return (
        <span
            className={styles.addRow}
            onClick={() => {
                const entry = newEntry(table, state);
                dispatch({
                    type: 'ADD_ROW',
                    payload: {
                        table,
                        entry,
                    }
                })
            }}
        >
            <Plus className={styles.plus} />
            
            {getName(table)}
            {table === 'parts' && <KeyboardEventHandler
                handleKeys={['alt+a', 'b', 'c']}
                onKeyEvent={(key, e) => {
                    const entry = newEntry(table);
                    dispatch({
                        type: 'ADD_ROW',
                        payload: {
                            table,
                            entry,
                        }
                    })
                }} />
            }

        </span>
    )
}

//general use in all tables
export const AuthInput = ({ row, rowIndex, table }) => {
    const { state, dispatch } = useContext(AdjudicationContext)
    const entry = state[table][rowIndex];
    if (state.isTableReadOnly) return (
        <div className={styles.readOnlyInput}>
            <NumberFormat
                prefix='$'
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType="text"
                value={entry.authorized}
            />
        </div>
    )
    return (
        <NumberFormat
            prefix="$"
            disabled={row.isAuthDisabled}
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            key={[row.id, 'auth'].join('_')}
            value={entry.authorized}
            onChange={e => {
                const numerify = Number(e.target.value.replace(/[^0-9.-]+/g, ""));
                dispatch({
                    type: 'EDIT_AUTHORIZED_AMOUNT',
                    payload: {
                        table,
                        rowIndex,
                        rowId: row.id,
                        authorized: numerify
                    }
                })
            }}
            style={{
                border: 'none',
                textAlign: 'right'
            }}
        />
    )
}

//general use in all tables
export const QtyInput = ({ row, rowIndex, table }) => {
    const [val, setVal] = useState(row.qty)

    const {
        state: {
            isTableReadOnly
        },
        dispatch
    } = useContext(AdjudicationContext);

    if (isTableReadOnly) return (
        <div style={{ textAlign: 'center' }}>
            {row.qty}
        </div>
    )
    return (
        <input
            key={[row.id, 'qty'].join('_')}
            value={table === 'labor' ? parseFloat(val) : parseInt(val)}
            type={'number'}
            onChange={e => setVal(Number(e.target.value.replace(/[^0-9.-]+/g, "")))}
            onBlur={(e) => {
                dispatch({
                    type: 'ADJUST_QTY',
                    payload: {
                        table,
                        rowId: row.id,
                        rowIndex,
                        qty: val
                    }
                })
            }}
            style={{
                border: 'none',
                textAlign: 'center'
            }}
        />

    )
}

//general use in all tables
export const UnitPriceInput = ({ row, rowIndex, table, isEdit = true }) => {
    const { state, dispatch } = useContext(AdjudicationContext)
    const entry = state[table][rowIndex]
    const [val, setVal] = useState(entry.unitPrice)


    // IF READ-ONLY, DISPLAY TEXT VERSION
    if (state.isTableReadOnly) return (
        <div style={{ textAlign: 'right', padding: '6px' }}>
            <NumberFormat
                prefix='$'
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType="text"
                value={row.unitPrice}
            />
        </div>
    )
    //Condition Based Disable inputs
    if (!isEdit && table == "parts") {
        return (<NumberFormat isAllowed={false}
            prefix='$'
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            key={[row.id, 'unit'].join('_')}
            value={0}
            onBlur={() => {
                setVal(0);
                const numerify = Number(0);
                dispatch({
                    type: 'ADJUST_UNIT_PRICE',
                    payload: {
                        table,
                        rowIndex,
                        rowId: row.id,
                        unitPrice: numerify
                    }
                })
            }}
            style={{
                border: 'none',
                textAlign: 'right'
            }}
        />)
    }


    // ELSE DISPLAY INPUT VERSION
    return (
        <NumberFormat
            prefix='$'
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            key={[row.id, 'unit'].join('_')}
            value={entry.unitPrice}
            onChange={e => { setVal(Number(e.target.value.replace(/[^0-9.-]+/g, ""))); entry.unitPrice = Number(e.target.value.replace(/[^0-9.-]+/g, "")); }}
            onBlur={() => {
                const numerify = Number(val);
                entry.unitPrice = numerify;
                dispatch({
                    type: 'ADJUST_UNIT_PRICE',
                    payload: {
                        table,
                        rowIndex,
                        rowId: row.id,
                        unitPrice: numerify
                    }
                })
            }}
            style={{
                border: 'none',
                textAlign: 'right'
            }}
        />
    )


}

//specific to parts
//todo: add autocomplete
export const DescInput = ({ row, rowIndex }) => {
    const { dispatch } = useContext(AdjudicationContext)
    return (
        <input
            key={[row.id, 'desc'].join('_')}
            defaultValue={row.description}
            type={'text'}
            onBlur={e => {
                dispatch({
                    type: 'UPDATE_DESCRIPTION',
                    payload: {
                        rowIndex,
                        description: e.target.value,
                    }
                })
            }}
            style={{
                border: 'none',
            }}
        />

    )
}

export const PartsDescriptionDropDown = ({ row, rowIndex, ...rest }) => {
    const { state, dispatch } = useContext(AdjudicationContext)

    if (state.isTableReadOnly) return (
        <div style={{ padding: '6px' }}>
            {row.description}
        </div>
    )

    return (
        <DropDownAutoComplete
            name="desc"
            options={state.assets}
            className="form-control"
            placeholder=""
            defaultValue={state.parts[rowIndex].description}
            Value={state.parts[rowIndex].description}
            onChangedValue={val => dispatch({
                type: 'UPDATE_DESCRIPTION',
                payload: {
                    rowIndex,
                    description: val
                }
            })}
            {...rest}
        />
    )
}
//specific to parts
export const PartNumberInput = ({ row, rowIndex }) => {
    const {
        state: {
            isTableReadOnly
        },
        dispatch
    } = useContext(AdjudicationContext)

    if (isTableReadOnly) return (
        <div style={{ padding: '6px' }}>
            {row.partNumber}
        </div>
    )

    return (
        <input
            key={[row.id, 'partNum'].join('_')}
            defaultValue={row.partNumber}
            type={'text'}
            onBlur={e => {
                dispatch({
                    type: 'UPDATE_PART_NUMBER',
                    payload: {
                        rowIndex,
                        partNumber: e.target.value,
                    }
                })
            }}
            style={{
                border: 'none',
            }}
        />

    )
}

export const RateType = ({ row, rowIndex, ...rest }) => {
    const { state, dispatch } = useContext(AdjudicationContext);
    const currentTable = state.labor;
    // WIREUP: load rateTypes from state
    const [rateTypes, setRateTypes] = useState(state.negotiatedRateTypes?.sort((a, b) => a.display.toLowerCase().localeCompare(b.display.toLowerCase())));
    let selectedValues = [];
    currentTable.forEach((rT, index) => {
        if (index != rowIndex)
            selectedValues.push(rT.rateType.data)
    })
    const rowFilteredItems = rateTypes.filter(item => !selectedValues.includes(item.data));
    const handleChange = (val) => {
        const rateType = rateTypes.find(rT => rT.data === val)
        dispatch({
            type: 'RATE_TYPE',
            payload: {
                rowIndex,
                rowId: row.id,
                rateType
            }
        })
    }

    if (state.isTableReadOnly) return (
        <div style={{ padding: '6px' }}>
            {state.labor[rowIndex].rateType.display}
        </div>
    )

    return (
          <DropDownAutoComplete
            name={[row.id, 'rateType'].join('_')}
            options={rowFilteredItems}
            className={`${styles.fontSizeReset} queueDropdown`}
            placeholder="Select..."
            defaultValue={state.labor[rowIndex].rateType.data}
            Value={state.labor[rowIndex].rateType.data}
            onChangedValue={handleChange}
            {...rest}
        />
    )
}

export const SelectCoverage = ({ row, rowIndex, table }) => {
    const { state, dispatch } = useContext(AdjudicationContext)
    // WIREUP: LOAD COVERAGES FROM STATE.
    const [options] = useState(state.coverages)

    const handleChange = (value) => {
        const coverage = options.filter(o => o.data === value)
        dispatch({
            type: 'SELECT_COVERAGE',
            payload: {
                table,
                rowIndex,
                rowId: row.id,
                coverage
            }
        }
        )
    }
    return (
        <DropDownQueue
            className={`${styles.fontSizeReset} queueDropdown`}
            name={[row.id, 'cov'].join('_')}
            onChangedValue={handleChange}
            options={options}
            placeholder="Select..."
            defaultValue={state[table][rowIndex]?.coverage?.data}
        />
    )
}

export const TripType = ({ row, table,
    rowIndex,
    }) => {
    const { state, dispatch } = useContext(AdjudicationContext);
    const { tripTypes } = state;

    const handleChange = (val) => {
        const tripType = tripTypes.find(tT => tT.data === val)

        dispatch({
            type: 'TRIP_TYPE',
            payload: {
                tripType,
                table,
                rowIndex,
                rowId: row.id,
            }
        })
    }

    if (state.isTableReadOnly) return (
        <div style={{ padding: '6px' }}>
            {state.mileage[rowIndex].tripType.display}
        </div>
    )

    return (
        <DropDownQueue
            className={`${styles.fontSizeReset} queueDropdown`}
            name={[row.id, 'trip'].join('_')}
            onChangedValue={handleChange}
            options={tripTypes}
            placeholder="Select..."
            defaultValue={row.tripType?.data}
        />
    )
}

export const PartDetails = ({ row, rowIndex }) => {
    const { state, dispatch } = useContext(AdjudicationContext);
    const options = [
        //{
        //    display: 'Service Power',
        //    value: 'servicepower',
        //},
        {
            display: 'No Longer Available',
            value: 'nla',
        },
        {
            display: 'Covered by OEM',
            value: 'oem',
        },
    ];
    const selected = state.parts[rowIndex].details
    let selectedText = '';
    if (state.isTableReadOnly)
    {
        for (var k = 0; k < selected.length; k++) {
            if (selected[k] !='')
            selectedText += options.find(o => o.value === selected[k])?.display + ','
        }
       
    }

    const handleOptionClick = (e) => {
        const sel = options.find(o => o.value === e)
        const { value } = sel
        const nextSelected = [...selected]
        if (!selected.includes(value)) {
            nextSelected.push(value)
        } else {
            const selIndex = nextSelected.indexOf(value)
            nextSelected.splice(selIndex, 1)
        }
        dispatch({
            type: 'PARTS_DETAILS',
            payload: {
                details: [...nextSelected],
                rowIndex
            }
        })
    }

    if (state.isTableReadOnly) return (
        <div style={{ padding: '6px' }}>
            {selected.length == 0 ? '' : selectedText.substring(0, selectedText.length-1)}
        </div>
    )

    return (
        <MultiDropDown
            onOptionClick={handleOptionClick}
            options={options}
            selected={selected}
            isInTableCell={true}
            borderless
        />
    )
}

export const EditRequestedTax = ({ row, rowIndex }) => {
    const { state, dispatch } = useContext(AdjudicationContext)
    const entry = state.tax[rowIndex]
    const [val, setVal] = useState(entry.requested)

    if (state.isTableReadOnly) return (
        <div style={{ textAlign: 'right', padding: '6px' }}>
            <NumberFormat
                prefix='$'
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                displayType="text"
                value={val}
            />
        </div>
    )
    return (
        <NumberFormat
            prefix="$"
            thousandSeparator={true}
            decimalScale={2}
            fixedDecimalScale={true}
            key={[row.id, 'reqTax'].join('_')}
            value={val}
            onChange={e => setVal(Number(e.target.value.replace(/[^0-9.-]+/g, "")))}
            onBlur={e => {
                dispatch({
                    type: 'EDIT_REQUESTED_TAX',
                    payload: {
                        rowIndex,
                        rowId: row.id,
                        requested: val
                    }
                })
            }}
            style={{
                border: 'none',
                textAlign: 'right'
            }}
        />
    )
}