import React, { useContext, useEffect } from 'react'
import { Col, FormGroup, Row } from "reactstrap";
import { DropDownAutoComplete } from "../inputs/DropDownAutoComplete";
import { Controller } from "react-hook-form";
import StandardInput from '../shared/StandardInput';
import { fetchBrandsByAccountId, getProductionDescriptionFromProd, loadSeries, loadWarrantyOptions } from '../../lib/ProductInputUtils';
import { useState } from 'react';
import OrderInfoProvider from '../../context/OrderInfoProvider';
import { SessionContext } from '../../context/SessionContext';
import { useClaimInvoiceStore } from './lib/state';
import { ContextCombined } from '../../context/CombinedContext';
import classNames from "classnames";
import styles from "../InvoiceTables/Table/Table.module.css";

// Replacement Details Section ON CLICK Unit Replaced
const ReplacementDetails = ({ formProps, dealerProfile }) => {
    const {
        register,
        control,
        setValue,
        trigger,
        watch,
        formState: { errors },
    } = formProps;

    const [brandOptions, setBrandOptions] = useState([]);
    const [brandDescOptions, setBrandDescOptions] = useState([]);
    const [warrantyPartsOptions, setWarrantyPartsOptions] = useState([]);
    const [warrantyLaborOptions, setWarrantyLaborOptions] = useState([]);
    const [seriesOptions, setSeriesOptions] = useState([]);

    const brandName = watch('brandName');
    const description = watch('brandName');
    const modelNumber = watch('modelNumber');
    const serialNumber = watch('serialNumber');
    const series = watch('series');
    const mfgParty = watch('mfgParty');
    const mfgLabor = watch('mfgLabor');
    const store = useClaimInvoiceStore(state => state);
    const invoiceData = Object.entries(store)?.filter(e => e[0].includes('invoice'));
    const storeData = invoiceData?.[0]?.[1];
    const assetId = storeData?.labor?.data?.[0]?.asset?.selected?.[0]?.assetId ?? '';

    const fetchOptions = async () => {
        let masterId = dealerProfile.masterProgramId;
        const res = await Promise.all([
            fetchBrandsByAccountId(dealerProfile.accountId),
            getProductionDescriptionFromProd(assetId, masterId),
            loadWarrantyOptions(dealerProfile.accountId),
            loadSeries()
        ]);

        const filtColAss = res?.[1]?.map((s) => {
            return {
                data: s.clientAssetMappingId,
                display: s.assetModel.assetName,
                id: s.assetId,
                clientAssetId: s.clientAssetId,
            };
        });
        const filtDataAss = [
            ...new Map(filtColAss.map((o) => [o.id, o])).values(),
        ];
        const sorted = filtDataAss.sort((a, b) =>
            a.display.localeCompare(b.display)
        );
       
        const brandOpts = res?.[0]?.map(e => ({ display: e.name, data: e.brandId }));
        const seriesOpts = res?.[3]?.map(e => ({ display: e.seriesDescription, data: e.seriesId }));
        const warrantyPartsOpts = res?.[2]?.sortedMfg?.map((val) => {
            val.display = `${val.data} year${val.data == "1" ? "" : "s"}`;
            return val;
        });
        const warrantyLaborOpts = res?.[2]?.sortedMfgLabor?.map((val) => {
            val.display = `${val.data} year${val.data == "1" ? "" : "s"}`;
            return val;
        });

        setBrandOptions(brandOpts);
        setSeriesOptions(seriesOpts);
        setWarrantyPartsOptions(warrantyPartsOpts);
        setWarrantyLaborOptions(warrantyLaborOpts);
        setBrandDescOptions(sorted);
        return {
            brandOpts,
            seriesOpts,
            warrantyPartsOpts,
            warrantyLaborOpts
        };
    }

    useEffect(() => {
        fetchOptions();
    }, [])

    return (
        <>
            <Row>
                <Col sm="4" className="mt-4">
                    <h4>Replacement Details</h4>
                </Col>
            </Row>
            <Row className="fiveColWrapper">
                <Col xs="12" md="3" className="col-5ths">
                    <Controller
                        control={control}
                        defaultValue={""}
                        name="brandName"
                        ref={register}
                        rules={{
                            required: 'Brand is required.',
                        }}
                        render={({ field, fieldState, formState }) => (
                            <FormGroup className={`${fieldState.error && 'form-error'}`}>
                                <label htmlFor="brandName" className="form-label">Brand</label>
                                <span aria-hidden="true" className="required-indicator">*</span>
                                <DropDownAutoComplete
                                    name="brandName"
                                    className="form-control"
                                    id="brandName"
                                    aria-required="true"
                                    options={brandOptions}
                                    Value={brandName}
                                    onChangedValue={(e) => {
                                        setValue('brandName', e)
                                        trigger('brandName');
                                      
                                    }
                                    }
                                />
                                <p className="errorMsg">{fieldState.error?.message}</p>
                            </FormGroup>
                        )}
                    />
                </Col>
                <Col xs="12" md="3" className="col-5ths">
                    <Controller
                        control={control}
                        defaultValue={""}
                        name="description"
                        ref={register}
                        rules={{
                            required: 'Description is required.',
                        }}
                        render={({ field, fieldState, formState }) => (
                            <FormGroup className={`${fieldState.error && 'form-error'}`}>
                                <label htmlFor="description" className="form-label">Description</label>
                                <span aria-hidden="true" className="required-indicator">*</span>
                                <DropDownAutoComplete
                                    name="description"
                                    className="form-control"
                                    id="description"
                                    aria-required="true"
                                    Value={description}
                                    options={brandDescOptions}
                                    onChangedValue={(e) => {
                                       
                                        setValue('description', e)
                                        trigger('description');
                                    }
                                    }
                                    validator={fieldState.error?.message}
                                />
                                <p className="errorMsg">{fieldState.error?.message}</p>

                            </FormGroup>
                        )}
                    />
                </Col>
                <Col xs="12" md="3" className={classNames("col-5ths", styles.labelAlignment)}>
                    <Controller
                        control={control}
                        defaultValue={""}
                        name="modelNumber"
                        rules={{
                            required: 'Model number is required.',
                        }}
                        ref={register}
                        render={({ field, fieldState, formState }) => (
                            <FormGroup className={`${fieldState.error && 'form-error'}`}>
                                <StandardInput
                                    hasError={fieldState.error?.message}
                                    label="Model Number"
                                    required
                                    fieldName="modelNumber"
                                    ariaLabelAndFieldId="modelNumber"
                                    value={modelNumber}
                                    onChange={(e) => {
                                        setValue('modelNumber', e.target.value)
                                        trigger('modelNumber');
                                    }
                                    }
                                    validator={fieldState.error?.message}
                                />
                            </FormGroup>
                        )}
                    />
                </Col>
                <Col xs="12" md="3" className={classNames("col-5ths", styles.labelAlignment)}>
                    <Controller
                        control={control}
                        defaultValue={""}
                        name="serialNumber"
                        rules={{
                            required: 'Serial number is required.',
                        }}
                        ref={register}
                        render={({ field, fieldState, formState }) => (
                            <FormGroup className={`${fieldState.error && 'form-error'}`}>
                                <StandardInput
                                    hasError={fieldState.error?.message}
                                    label="Serial Number"
                                    required
                                    fieldName="serialNumber"
                                    ariaLabelAndFieldId="serialNumber"
                                    value={serialNumber}
                                    onChange={(e) => {
                                        setValue('serialNumber', e.target.value)
                                        trigger('serialNumber');
                                    }
                                    }
                                    validator={fieldState.error?.message}
                                />
                            </FormGroup>
                        )}
                    />
                </Col>
                <Col xs="12" md="3" className="col-5ths">
                    <Controller
                        control={control}
                        defaultValue={""}
                        name="mfgParty"
                        rules={{
                            required: 'MFG Party Warranty is required.',
                        }}
                        ref={register}
                        render={({ field, fieldState, formState }) => (
                            <FormGroup className={`${fieldState.error && 'form-error'}`}>
                                <label htmlFor="mfgParty" className="form-label">MFG Party Warranty</label>
                                <span aria-hidden="true" className="required-indicator">*</span>
                                <DropDownAutoComplete
                                    name="mfgParty"
                                    className="form-control"
                                    id="mfgParty"
                                    aria-required="true"
                                    Value={mfgParty}
                                    options={warrantyPartsOptions}
                                    onChangedValue={(e) => {
                                        setValue('mfgParty', e)
                                        trigger('mfgParty');
                                    }
                                    }
                                    validator={fieldState.error?.message}
                                />
                                <p className="errorMsg">{fieldState.error?.message}</p>

                            </FormGroup>
                        )}
                    />
                </Col>
                <Col xs="12" md="3" className="col-5ths mt-0">
                    <Controller
                        control={control}
                        defaultValue={""}
                        name="mfgLabor"
                        rules={{
                            required: 'MFG Labor warranty is required.',
                        }}
                        ref={register}
                        render={({ field, fieldState, formState }) => (
                            <FormGroup className={`${fieldState.error && 'form-error'}`}>
                                <label htmlFor="brandName" className="form-label">MFG Labor Warranty</label>
                                <span aria-hidden="true" className="required-indicator">*</span>
                                <DropDownAutoComplete
                                    name="mfgLabor"
                                    className="form-control"
                                    id="mfgLabor"
                                    aria-required="true"
                                    Value={mfgLabor}
                                    options={warrantyLaborOptions}
                                    onChangedValue={(e) => {
                                        setValue('mfgLabor', e)
                                        trigger('mfgLabor');
                                    }
                                    }
                                    validator={fieldState.error?.message}
                                />
                                <p className="errorMsg">{fieldState.error?.message}</p>

                            </FormGroup>
                        )}
                    />
                </Col>
                <Col xs="12" md="3" className="col-5ths mt-0">
                    <Controller
                        control={control}
                        defaultValue={""}
                        name="series"
                        rules={{
                            required: 'Series is required.',
                        }}
                        ref={register}
                        render={({ field, fieldState, formState }) => (
                            <FormGroup className={`${fieldState.error && 'form-error'}`}>
                                <label htmlFor="brandName" className="form-label">Series</label>
                                <span aria-hidden="true" className="required-indicator">*</span>
                                <DropDownAutoComplete
                                    name="series"
                                    className="form-control"
                                    id="series"
                                    aria-required="true"
                                    options={seriesOptions}
                                    Value={series}
                                    onChangedValue={(e) => {
                                        setValue('series', e)
                                        trigger('series');
                                    }
                                    }
                                    validator={fieldState.error?.message}
                                />
                                <p className="errorMsg">{fieldState.error?.message}</p>

                            </FormGroup>
                        )}
                    />
                </Col>
            </Row>
        </>
    );
};
export default ReplacementDetails;