import React, { Component } from 'react';
import { Route, Redirect } from "react-router";
import ErrorConfirmation from '../ErrorConfirmation/ErrorConfirmation';
import DealerTerms from '../DealerTerms/DealerTerms';
import CombinedContextProvider, { ContextCombined } from '../../context/CombinedContext';
import ErrorPasswordExceeded from '../ErrorConfirmation/ErrorPasswordExceeded';

class ProtectedRouteCheck extends Component {
    constructor(props) {
        super(props);
        this.state = {
            path: props.path,
            component: props.component
        }
    }
    render() {
        const { component: Component, ...rest } = this.props;
        const authContext = this.context.authContext;
        const sessionContext = this.context.sessionContext;
        var needsToReaccept = sessionContext.needsToReacceptFlag();

        return <Route {...rest} render={(props) => {

            if (authContext.isSessionValid() && needsToReaccept) {
                if (authContext.token != null) {
                    return <DealerTerms profileModel={sessionContext.getProfile()} />
                } else {
                    return <Redirect to="/login" />
                }
            }
            else if (authContext.isSessionValid() && !needsToReaccept) {
                if (authContext.token != null) {
                    return <Component {...props} />;
                } else {
                    return <Redirect to="/login" />
                }
            } else {
                if (sessionContext.loginExceeded == false) {
                    return <Redirect to="/login" />
                } else {
                    return <ErrorPasswordExceeded />;
                }
            }
        }} />;
    }
}
ProtectedRouteCheck.contextType = ContextCombined;

const ProtectedRoute = props => {
    return (
        <CombinedContextProvider>
            <ProtectedRouteCheck {...props} />
        </CombinedContextProvider>
    );
};

export default ProtectedRoute;