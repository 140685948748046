import React, { Component, Fragment } from "react";
import { Card, CardBody, Col, FormGroup, Input, Label, Row } from "reactstrap";
import classNames from 'classnames';
import { AddProducts } from './AddProducts';
import moment from 'moment';
import Calendar from 'react-calendar';
import Cleave from 'cleave.js/react';
import { ReactComponent as CalendarIcon } from '../../../assets/images/calendar.svg';
import { DropDownAutoComplete } from '../../../components/inputs/DropDownAutoComplete';
import SimpleReactValidator from 'simple-react-validator';
import SuggestedAddress from '../../../components/Modals/SuggestedAddress';
import InvalidAddress from '../../../components/Modals/InvalidAddress';
import { verifyAddress, VerificationResponseCodes, getLocationFromZip } from '../../../api/addressverification';
import OrderInfoContext from '../../../context/OrderInfoContext'
import styles from "./AddProducts.module.css";
import { dataLayerPush } from '../../Global/helpers'

let dateInput = "dateInput";
let formControl = "form-control";
let installationDateWidth = styles.installationDateWidth;
let caDateFormat = "DD/MM/YYYY";
let usDateFormat = "MM/DD/YYYY";

export class OrderInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            party: this.props.party,
            states: [],
            tempDate: '',
            showCalendar: false,
            showSuggestedAddressValidationModal: false,
            verifiedAddress: null,
            addressToVerify: null,
            showInvalidAddressModal: false,
            installationDate: "",
            installationAddress: "",
            installationStateId: 0,

            dealerOptions: {
                countryId: 1,
            },
            errors: {
                installationType: false,
                installationDate: false,
                installationAddress: false,
                installationCity: false,
                installationState: false,
                installationPostal: false,
                installDateLimitError: false,
                installationPostalLength: false,
                installationPostalCountry: false
            },

            clientId: '',

            productAgingSetup: [],
            installType: 'Residential',
            installDateLimitErrorMsg: ""
        }

        this.handleCleaveDate = this.handleCleaveDate.bind(this);
        this.handleCalendarDate = this.handleCalendarDate.bind(this);
        this.openCalendar = this.openCalendar.bind(this);
        this.handleStateDropDown = this.handleStateDropDown.bind(this);
        this.checkStateForErrors = this.checkStateForErrors.bind(this);
        this.verifyAddress = this.verifyAddress.bind(this);
        this.onAddressVerified = this.onAddressVerified.bind(this);
        this.onSaveAddress = this.onSaveAddress.bind(this);
        this.save = this.save.bind(this);
        this.retrievePartyInfo = this.retrievePartyInfo.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onZipVerified = this.onZipVerified.bind(this);
        this.onContinueSaveAction = this.onContinueSaveAction.bind(this);
        this.onCloseAddressModal = this.onCloseAddressModal.bind(this);
        this.checkAddress = this.checkAddress.bind(this);
        this.checkInstallationDateForErrors = this.checkInstallationDateForErrors.bind(this);

        this.validator = new SimpleReactValidator({
            element: message => <div className="errorMsg">{message.charAt(0).toUpperCase() + message.slice(1)}</div>,
            messages: {
                required: ':attribute is required.'
            }
        });
    }

    isCanadianDealer = () => {
        return this.state.dealerOptions.countryId == 2;
    }

    componentDidMount() {
        dataLayerPush('Pageview', {
            pageTitle: "Sales Order Info",
            pagePath: "/sales/orderinfo"
        })
        try {
            fetch('party/states')
                .then(res => res.json())
                .then(data => {
                    this.setState({
                        states: data.map(s => {
                            return {
                                data: s.stateId,
                                display: s.name,
                                abbreviation: s.abbreviation,
                                country: s.countryModel?.iso2,
                                countryId: s.countryModel?.countryId
                            }
                        }).filter(s => s.countryId === this.state.dealerOptions.countryId).sort((a, b) => {
                            if (a.display < b.display) { return -1; }
                            if (a.display > b.display) { return 1; }
                            return 0;
                        })
                    });
                    var stateSelected = this.state.states.find(s => s.abbreviation === this.props.installtionStateAbb);
                    if (stateSelected != undefined)
                        this.setState({ installationStateId: stateSelected.data });
                })

            let installationDateMoment = moment(this.props.installationDate, this.getDateFormat());
            this.setState({
                clientId: 'apple',
                tempDate: this.props.installationDate,
                installationDate: installationDateMoment.isValid() ? installationDateMoment.toDate() : "",
                installationAddress: this.props.installationAddress,
                installationCity: this.props.installationCity,
                installationPostal: this.props.installationPostal,
                installationAddress2: this.props.installationAddress2,
            });

            this.setState({
                dealerOptions: this.context.dealerProfile
            });
        } catch (error) {
            console.error(error);
        }

        this.getProductAgingSetup();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.installationStateId != this.state.installationStateId ||
            prevState.installationPostal != this.state.installationPostal ||
            prevState.installationState != this.state.installationState) {
            let context = this.context;
            context.setField("stateId", this.state.installationStateId);
            context.setField("zip", this.state.installationPostal);
        }
    }

    getDateFormat = () => {
        return this.isCanadianDealer() ? caDateFormat : usDateFormat;
    }

    handleCleaveDate = e => {
        let cleaveDate = e.target.value;
        let date = moment(cleaveDate, this.getDateFormat()).toDate();
        let stateErrs = this.state.errors;

        stateErrs.installationDate = false;
        stateErrs.installDateLimitError = false;

        this.setState({
            installDateLimitErrorMsg: "",
            errors: stateErrs
        })

        if (!isNaN(date.getTime())) {
            this.setState({
                tempDate: cleaveDate,
                installationDate: date
            }, () => {
                this.props.updateOrderParty("installationDate", this.isCanadianDealer() ?
                    moment(this.state.installationDate).format("DD/MM/YYYY") : moment(this.state.installationDate).format("MM/DD/YYYY"));
                this.setContextInstallationDate(this.state.installationDate);
                this.openCalendar(false);
            });
        }
        else {
            this.setState({
                tempDate: cleaveDate
            });
        }

        this.checkProductAging(cleaveDate);

        if (this.validator.helpers.testRegex(cleaveDate, /^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/)) {
            this.openCalendar(false);
        }
    }

    handleCalendarDate = e => {
        let stateErrs = this.state.errors;
        stateErrs.installDateLimitError = false;
        stateErrs.installationDate = false;

        this.setState({
            tempDate: this.isCanadianDealer() ? moment(e).format("DD/MM/YYYY") : moment(e).format("MM/DD/YYYY"),
            installationDate: e,
            installDateLimitErrorMsg: "",
            errors: stateErrs
        }, () => {
            this.props.updateOrderParty("installationDate", this.isCanadianDealer() ? moment(e).format("DD/MM/YYYY") : moment(e).format("MM/DD/YYYY"));
            this.setContextInstallationDate(this.state.installationDate);
            this.checkProductAging(e);
            this.openCalendar(false);
        });
    }

    setContextInstallationDate = (date) => {
        let context = this.context;
        context.setInstallationDate(date);
    }

    openCalendar = (e) => {
        this.setState({
            showCalendar: !e
        })
    }

    handleStateDropDown = e => {
        this.setState({ installationStateId: e });
        const state = this.state.states.find(({ data }) => data === e).display;
        const stateAbb = this.state.states.find(({ data }) => data === e).abbreviation;
        this.props.updateOrderParty("installationState", state, this.state.states, stateAbb, e);

    }

    checkStateForErrors() {
        const err = this.state.errors;
        const installStateId = this.state.installationStateId;

        if (installStateId > 0) {
            err.installationState = false;
        }
        else {
            err.installationState = true;
        }

        this.setState({ errors: err });
    }

    verifyAddress = () => {
        let state = this.state.states.find(state => state.data === this.state.installationStateId);

        let addressToVerify = {
            address1: this.props.installationAddress,
            address2: this.props.installationAddress2,
            city: this.props.installationCity,
            state: (state != null && state.display != null) ? state.display : "",
            zip: this.props.installationPostal,
        }

        this.setState({
            addressToVerify: addressToVerify
        });

        verifyAddress(addressToVerify, this.state.clientid, this.onAddressVerified);
    }

    onAddressVerified = (result) => {
        let { installationPostalCountry } = this.state.errors;
        let { showSuggestedAddressValidationModal, verifiedAddress, showInvalidAddressModal } = this.state;
        let addressHasError = true;
        let doCallback = false;

        switch (result.code) {
            case VerificationResponseCodes.addressVerified:
                addressHasError = false;
                showSuggestedAddressValidationModal = false;
                doCallback = true;
                break;

            case VerificationResponseCodes.addressChanged:
                showSuggestedAddressValidationModal = true;
                verifiedAddress = result.returnedAddress;
                break;

            default:
                if (!installationPostalCountry) {
                    showInvalidAddressModal = true;
                }

                break;
        }

        this.props.handleContinueValidationClick(addressHasError);

        this.setState({
            showSuggestedAddressValidationModal,
            verifiedAddress,
            showInvalidAddressModal
        }, () => {
            if (doCallback) {
                this.onSaveAddress();
            }
        });
    }

    onSaveAddress = () => {
        document.getElementById('installationAddress').value = this.state.installationAddress;
        document.getElementById('installationAddress2').value = this.props.installationAddress2;
        document.getElementById('installationCity').value = this.props.installationCity;
        document.getElementById('installationPostal').value = this.props.installationPostal;

        //this.save();
    }

    save = () => {
        this.setState(prevState => ({
            saving: !prevState.saving
        }));
        if (this.validateForm(this.state.errors)) {
            fetch('party/' + this.state.party.partyId + '/arw', {
                method: 'put',

                headers: {
                    'Content-Type': 'application/json'
                },

                body: JSON.stringify(this.state.party)
            }).then(res => {
                if (res.ok) {
                    res.json().then(data => {
                        this.setState(prev => ({
                            party: data,
                            saving: !prev.saving,
                            modal: !prev.modal
                        }));

                        let value = this.context;
                        value.updateParty(data);
                        this.sendBackYourInfo();
                    })
                } else {
                    this.toggle();
                }
            });
        }
        else {
            this.setState(ps => ({ saving: !ps.saving }));
            console.error("Invalid Form");
        }
    }

    retrievePartyInfo = async (partyId) => {
        fetch('party/'.concat(partyId).concat('/').concat(this.state.clientId))
            .then(res => res.json())
            .then(data => {
                this.setState({ party: data });
            });
    }

    handleInputChange = e => {
        const { id, value } = e.target;
        this.setState({ id: value });
        let fieldName = id;
        let error = this.state.errors;

        let ipostallength = document.getElementById("installationPostal").value.replace(/ /g, '').length;
        this.setState({ installationPostalLength: ipostallength });

        if (fieldName === "installationPostal") {
            if (value.length >= 5) {
                this.props.updateOrderParty("installationPostal", value);
                getLocationFromZip(value, this.state.clientId, this.onZipVerified);
            }
        }

        const radioRes = document.getElementById('ResidentialPT') != null ? document.getElementById('ResidentialPT').checked : false;
        const radioCom = document.getElementById('CommercialPT') != null ? document.getElementById('CommercialPT').checked : false;

        if (radioRes === false && radioCom === false && this.props.isLennoxDealer) {
            error.installationType = true;
            this.setState({ errors: error });
        }
        else {
            error.installationType = false;
            this.setState({ errors: error });
        }

        if (error.installationDate === true) {
            this.checkInstallationDateForErrors();
        }

        if (error.installationState === true) {
            this.checkStateForErrors();
        }

        if (fieldName === "installationAddress") {
            this.setState({ installationAddress: value });
        }

        switch (fieldName) {
            case "installationAddress":
                this.props.updateOrderParty("installationAddress", value);
                if (value.trim() === "") {
                    error.installationAddress = true;
                }
                else {
                    error.installationAddress = false;
                }

                this.setState({
                    errors: error
                });

                break;

            case "installationAddress2":
                this.props.updateOrderParty("installationAddress2", value);
                this.setState({ installationAddress2: value });

                break;


            case "installationCity":
                this.props.updateOrderParty("installationCity", value);
                if (value.trim() === "") {
                    error.installationCity = true;
                }
                else {
                    error.installationCity = false;
                }

                this.setState({
                    errors: error,
                    installationCity: value
                });

                break;

            case "installationPostal":
                this.props.updateOrderParty("installationPostal", value);
                if (value.trim() === "") {
                    error.installationPostal = true;
                }
                else if (value.replace(/ /g, '').length < 5) {
                    error.installationPostal = true;
                }
                else {
                    error.installationPostal = false;
                    error.installationPostalCountry = false;
                }

                this.setState({
                    errors: error,
                    installationPostal: value
                });

                break;

            default:
                break;
        }
    }

    onZipVerified = (result) => {
        if (result.successful) {
            var state = this.state.states.find(({ abbreviation }) => abbreviation === result.location.state);

            if (state) {
                this.props.updateOrderParty("installationCity", result.location.city);
                this.props.updateOrderParty("installationState", state.display, this.state.states, state.abbreviation, state.data);
                this.setState({ installationCity: result?.location?.city });
                this.setState({
                    installationStateId: state.data,
                })

                document.getElementById('installationCity').value = this.props.installationCity;
            }
            else {
                this.setState({ errors: { installationPostalCountry: true } });
            }
        }
    }

    validateOrderInformation() {
        //called as REF from OrderInfoContainer

        const err = this.state.errors;

        // Set the errors to default values

        err.installationType = false;
        err.installationDate = false;
        err.installationAddress = false;
        err.installationCity = false;
        err.installationState = false;
        err.installationPostal = false;
        err.installDateLimitError = false;

        this.setState({ errors: err });

        const radioRes = document.getElementById('ResidentialPT') != null ? document.getElementById('ResidentialPT').checked : false;
        const radioCom = document.getElementById('CommercialPT') != null ? document.getElementById('CommercialPT').checked : false;

        if (radioRes === false && radioCom === false && this.props.isLennoxDealer) {
            document.getElementById('ResidentialPT').focus();
            err.installationType = true;
            this.setState({ errors: err });
        }

        const installDate = this.context.installationDate;
        if (installDate.length === 0) {
            err.installationDate = true;
            this.setState({ errors: err });
        }
        else {
            err.installationDate = false;
            err.installDateLimitError = this.checkProductAging(null);

            this.setState({ errors: err });
        }

        const installAdd = this.props.installationAddress;
        if (installAdd.length === 0) {
            document.getElementById('installationAddress').focus()
            err.installationAddress = true;
            this.setState({ errors: err });
        }

        const installCity = this.props.installationCity;
        if (installCity.length === 0) {
            document.getElementById('installationCity').focus()
            err.installationCity = true;
            this.setState({ errors: err });
        }

        const installStateId = this.props.installationStateId;
        const installState = this.props.installationState;
        if (typeof installState === "undefined" || installState === '' || installStateId === 0) {
            err.installationState = true;
            this.setState({ errors: err });
        }
        else {
            err.installationState = false;
            this.setState({ errors: err });
        }

        const installPostal = this.props.installationPostal;
        if (installPostal.length === 0) {
            document.getElementById('installationPostal').focus()
            err.installationPostal = true;
            this.setState({ errors: err });
        }

        if (err.installationType === true ||
            err.installationDate === true ||
            err.installationAddress === true ||
            err.installationCity === true ||
            err.installationState === true ||
            err.installationPostal === true ||
            err.installDateLimitError === true) {
            return true;
        }
        else {
            return false;
        }
    };

    onContinueSaveAction = (selectedAddress) => {
        if (selectedAddress === "suggestedAddress") {
            let newAddress = this.state.verifiedAddress;
            let suggestedState;
            if (newAddress.state) {
                suggestedState = this.state.states.find(state => state.display.toUpperCase() === newAddress.state.toUpperCase());
            } else {
                suggestedState = this.state.states.find(state => state.data === this.state.installationStateId);
            }

            this.props.updateOrderParty("installationAddress", newAddress.address1);
            this.props.updateOrderParty("installationAddress2", newAddress.address2);
            this.props.updateOrderParty("installationState", suggestedState.display, this.state.states, suggestedState.abbreviation, suggestedState.data);
            this.props.updateOrderParty("installationCity", newAddress.city);
            this.props.updateOrderParty("installationPostal", newAddress.zip);

            this.setState({
                installationAddress: (`${newAddress.address1} ${newAddress.suite}`).trim(),
                installationStateId: suggestedState.data,
                showSuggestedAddressValidationModal: false,
            }, () => this.onSaveAddress());
            this.props.handleContinueValidationClick(false);
        } else {
            this.setState({
                showSuggestedAddressValidationModal: false,
                showInvalidAddressModal: false
            }, () => this.onSaveAddress());

            this.props.handleContinueValidationClick(false);
        }
    }

    onCloseAddressModal = () => {
        this.setState({
            showSuggestedAddressValidationModal: false,
            showInvalidAddressModal: false
        });
        this.props.checkIfCanContinue(true);
    }

    checkAddress() {
        const installAddress = document.getElementById('installationAddress').value;
        console.log({ installAddress })

        const installCity = document.getElementById('installationCity').value;
        console.log({ installCity })

        const installStateId = this.state.installationStateId;
        console.log({ installStateId });

        const installPostal = document.getElementById('installationPostal').value;
        console.log({ installPostal })

        if (installAddress.length > 0 &&
            installCity.length > 0 &&
            installStateId > 0 &&
            installPostal.length > 0) {
            this.verifyAddress();
        }
    }

    handleInstallationTypeChange = (type) => {
        this.props.updateOrderParty("installType", type);
        this.context.setInstallationType(type);
        this.props.updateProductTable([]);
        this.props.invokeIfInstallTypeChanged();
        this.setState({ installType: type });
        this.checkProductAging(null, type);
    }

    checkInstallationDateForErrors() {
        const err = this.state.errors;
        const installDate = this.props.installationDate;

        err.installationDate = installDate !== '' ? false : true;
        err.installDateLimitError = this.checkProductAging(null);

        this.setState({ errors: err });
    }


    getProductAgingSetup = () => {
        fetch('Asset/GetProductAgingSetup')
            .then(res => res.json())
            .then(data => {
                console.log(data);

                this.setState({ productAgingSetup: data });
            })
    }

    checkProductAging = (tempDate = "", installationType = "") => {
        let { installType, installationDate, productAgingSetup } = this.state;
        let installDateLimitError = false;
        let stateErrs = this.state.errors

        if (installationDate !== tempDate && tempDate !== null)
            installationDate = tempDate;

        if (installationType !== installType && installationType !== "")
            installType = installationType;

        if (productAgingSetup.length > 0) {
            let limitSetup = productAgingSetup.filter(x => { return x.type.toLowerCase() === installType.toLowerCase() });

            if (limitSetup.length > 0) {
                let productSetup = limitSetup[0];
                let currentDate = new Date();

                currentDate = moment(currentDate).format("YYYY-MM-DD");
                installationDate = moment(installationDate).format("YYYY-MM-DD");

                let res = moment(currentDate).diff(installationDate, productSetup.limitType);

                if (res >= productSetup.limit) {
                    let installDateLimitErrorMsg = productSetup.limit === 1 ? productSetup.limitType.substring(0, productSetup.limitType.length - 1) : productSetup.limitType;
                    installDateLimitErrorMsg = `Maximum install date cannot exceed ${productSetup.limit} ${installDateLimitErrorMsg}.`;
                    installDateLimitError = true;
                    stateErrs.installDateLimitError = true;

                    this.setState({ installDateLimitErrorMsg: installDateLimitErrorMsg, errors: stateErrs });
                }
                else {
                    stateErrs.installDateLimitError = false;

                    this.setState({ installDateLimitErrorMsg: '', errors: stateErrs });
                }
            }
        }

        return installDateLimitError;
    }

    render() {
        let stateOptions = this.state.states;
        const {showCalendar} = this.state;
        if (this.props.installtionStateAbb != null && this.props.installtionStateAbb != "" && this.state.installationStateId == 0) {
            var stateSelected = this.state.states.find(s => s.abbreviation === this.props.installtionStateAbb);
            if (stateSelected != undefined) {
                this.setState({ installationStateId: stateSelected.data, installationPostal: this.props.installationPostal });
                this.props.updateOrderParty("installationState", stateSelected.display, this.state.states, stateSelected.abbreviation, stateSelected.data);

                let context = this.context;
                context.setField("zip", this.props.installationPostal);
            }
        }
        if (this.props.installationDate != null && this.props.installationDate != "" && this.context.installationDate != this.props.installationDate) {
            this.context.installationDate = this.props.installationDate;

        }

        return (
            <div>
                <Card>
                    <CardBody>
                        <Row className="multiColWrapper">
                            {this.props?.profile?.isInstallTypeVisible && (
                                <Col xs="6" md="4" className="col-5ths">
                                    <FormGroup className={this.state.errors.installationType ? "form-error mb-0" : "mb-0"}>
                                        <Label className="form-label" aria-label="Installation Type" for="installationTypeSelection">
                                            Type of Installation
                                        </Label>
                                        <span aria-hidden="true" className="required-indicator">*</span>

                                        <div className="radio">
                                            <Input type="radio" name="installationType" id="ResidentialPT" value="Residential"
                                                onChange={() => this.handleInstallationTypeChange('Residential')}
                                                checked={this.context.installationType === 'Residential'}
                                            />
                                            <label className="controls-label" for="ResidentialPT">Residential</label>
                                        </div>

                                        {this.props.hideCommercialInstallType === false && <div className="radio">
                                            <Input type="radio" name="installationType" id="CommercialPT" value="Commercial"
                                                onChange={() => this.handleInstallationTypeChange('Commercial')}
                                                checked={this.context.installationType === 'Commercial'}
                                            />
                                            <label className="controls-label" for="CommercialPT">Commercial</label>
                                        </div>}

                                        {this.state.errors.installationType === true && (
                                            <div className="errorMsg">Type of installation is required.</div>
                                        )}
                                    </FormGroup>
                                </Col>
                            )}

                            <Col xs="6" md="4" className={classNames("col-5ths", installationDateWidth)}>
                                <FormGroup
                                    className={`mb-0 ${(this.state.errors.installDateLimitError || this.state.errors.installationDate ||
                                            (this.props.oemDataLoaded && !this.props.installationDate)) &&
                                        "form-error"
                                        }`}
                                >
                                    <label for="installationDate" className="form-label">Installation Date</label>
                                    <span aria-hidden="true" className="required-indicator">*</span>

                                    {this.isCanadianDealer() === false &&
                                        <div className="calendar-input">
                                            <Cleave
                                                onFocus={() => this.openCalendar(showCalendar)}
                                                disabledname="installationDate"
                                                className={classNames(formControl, dateInput)}
                                                value={this.props.installationDate == "" ? this.state.tempDate : this.props.installationDate}
                                                onChange={this.handleCleaveDate}
                                                options={{
                                                    date: true,
                                                    delimiter: '/',
                                                    datePattern: ['m', 'd', 'Y'],
                                                }} />
                                                <CalendarIcon
                                                    className="datePicker"
                                                    name="calendarIcon"
                                                    onClick={() => this.openCalendar(showCalendar)}
                                                />
                                        </div>
                                    }
                                    {this.isCanadianDealer() === true &&
                                        <div className="calendar-input">
                                        <Cleave
                                            onFocus={() => this.openCalendar(showCalendar)}
                                            name="installationDate"
                                            className={classNames(formControl, dateInput)}
                                            value={this.props.installationDate == "" ? this.state.tempDate : this.props.installationDate}
                                            onChange={this.handleCleaveDate}
                                            options={{
                                                date: true,
                                                delimiter: '/',
                                                datePattern: ['d', 'm', 'Y'],
                                            }} />

                                            <CalendarIcon
                                                className="datePicker"
                                                name="calendarIcon"
                                                onClick={() => this.openCalendar(showCalendar)}
                                            />
                                        </div>
                                    }
                                    {this.state.showCalendar &&
                                        <Calendar
                                            name="installationDate"
                                            value={this.state.installationDate}
                                            maxDate={new Date()}
                                            onChange={this.handleCalendarDate}
                                        />
                                    }
                                    {(this.state.errors.installationDate || (this.props.oemDataLoaded && !this.props.installationDate)) &&
                                        <div className="errorMsg">Installation date is required.</div>
                                    }
                                    {(this.state.errors.installDateLimitError && !this.state.errors.installationDate) && <div className="errorMsg">{this.state.installDateLimitErrorMsg}</div>}
                                </FormGroup>
                            </Col>
                        </Row>
                        <hr className="cardSectionHR" />
                        <div className="installAddress">
                            <h2 className="h5 subHead">Installation Address</h2>
                            <Col lg="4" className="p-0">
                                <FormGroup className={((this.props.installationAddress == "" && this.props.oemDataLoaded) || this.state.errors.installationAddress) ? "form-error" : ""}>
                                    <label for="installationAddress" className="form-label">Street Address</label>
                                    <span aria-hidden="true" className="required-indicator">*</span>

                                    <Input className="form-control"
                                        type="text"
                                        id="installationAddress"
                                        value={this.props.installationAddress}
                                        maxLength={30}
                                        onChange={this.handleInputChange}
                                        autocomplete="address-line1" />

                                    {this.state.errors.installationAddress && <div className="errorMsg">Street address is required.</div>}
                                </FormGroup>

                                <FormGroup>
                                    <label for="installationAddress2" className="form-label">Apt., Suite, Building</label>
                                    <Input className="form-control"
                                        type="text"
                                        id="installationAddress2"
                                        maxLength={30}
                                        value={this.props.installationAddress2}
                                        onChange={this.handleInputChange}
                                        autocomplete="address-line2" />
                                </FormGroup>
                                <Row className="twoColWrapper">
                                    <Col sm="6">
                                        <FormGroup className={`mb-sm-0 ${((this.props.installationPostal == "" && this.props.oemDataLoaded) || (this.state.errors.installationPostal || this.state.errors.installationPostalCountry)) ? "form-error" : ""}`}>
                                            <label for="installationPostal" className="form-label">Zip/Postal</label>
                                            <span aria-hidden="true" className="required-indicator">*</span>
                                            <Input className="form-control"
                                                type="text"
                                                id="installationPostal"
                                                maxLength={10}
                                                value={this.props.installationPostal}
                                                onChange={this.handleInputChange}
                                                autocomplete="postal-code" />
                                            {this.state.installationPostalLength < 5 && this.state.installationPostal.trim() !== "" && <div className="errorMsg">Zip/Postal is invalid.</div>}
                                            {((this.props.installationPostal == "" && this.props.oemDataLoaded) || (this.state.errors.installationPostal === true && this.state.installationPostal.trim() === "")) && <div className="errorMsg">Zip/Postal is required.</div>}
                                            {this.state.errors.installationPostalCountry && !this.state.errors.installationPostal && <div className="errorMsg">Sale cannot be outside {this.state.dealerOptions.country} for this account.</div>}
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup className={`mb-0 ${((this.props.installationStateId == "" && this.props.oemDataLoaded) || this.state.errors.installationState) ? "form-error" : ""}`}>
                                            <label for="installationState" className="form-label">{this.isCanadianDealer() ? "Province" : "State"}</label>
                                            <span aria-hidden="true" className="required-indicator">*</span>
                                            <DropDownAutoComplete name="state"
                                                options={stateOptions}
                                                className="form-control"
                                                onChangedValue={this.handleStateDropDown}
                                                onBlur={this.handleStateDropDown}
                                                placeholder="Please select..."
                                                defaultValue={((this.props.installationStateId === '' || this.props.installationStateId === null) ? undefined : this.props.installationStateId)}
                                                value={this.props.installationStateId}
                                                stateFlag={true}
                                            />
                                            {((this.props.installationStateId == "" && this.props.oemDataLoaded) || this.state.errors.installationState === true) && <div className="errorMsg">State is required.</div>}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <FormGroup className={`mb-0 ${(this.props.installationCity == "" && this.props.oemDataLoaded) || this.state.errors.installationCity ? "form-error" : ""}`}>
                                    <label for="installationCity" className="form-label">City</label>
                                    <span aria-hidden="true" className="required-indicator">*</span>

                                    <Input className="form-control"
                                        type="text"
                                        id="installationCity"
                                        value={this.props.installationCity}
                                        maxLength={20}
                                        onChange={this.handleInputChange}
                                        autocomplete="address-level2" />

                                    {((this.props.installationCity == "" && this.props.oemDataLoaded) || this.state.errors.installationCity) && <div className="errorMsg">City is required.</div>}
                                </FormGroup>
                            </Col>
                        </div>
                    </CardBody>

                    {this.state.showSuggestedAddressValidationModal &&
                        <SuggestedAddress
                            showSuggestedAddressValidationModal={this.state.showSuggestedAddressValidationModal}
                            enteredAddress={this.state.addressToVerify}
                            suggestedAddress={this.state.verifiedAddress}
                            onContinueAction={this.onContinueSaveAction}
                            onEditAddress={this.onCloseAddressModal} />
                    }

                    {this.state.showInvalidAddressModal &&
                        <InvalidAddress
                            showInvalidAddressModal={this.state.showInvalidAddressModal}
                            onEditAddress={this.onCloseAddressModal}
                            onContinueAction={this.onContinueSaveAction} />
                    }
                </Card>
            </div >
        );
    }
}

OrderInformation.contextType = OrderInfoContext;