import React from "react";
import { Row, Col } from "reactstrap";
import { getBrandContent } from '../../../BrandingWhiteLabel/BrandContent';
import BrandedIcon from '../../../BrandingWhiteLabel/BrandedIcon';


import styles from './WhySell.module.css';
import { useContext } from "react";
import { SessionContext } from "../../../../context/SessionContext";



const WhySell = () => {
  const context = useContext(SessionContext)
  const { accountName } = context
  const accountNameToLower = accountName.toLowerCase();
  return (
    <>
      <h1 className="h5">Why Sell</h1>
      <Row className="m-0">
        <Col lg="6" className={styles.whySellPoints}>
          <span>
            {accountNameToLower !== 'ge' 
              ? <BrandedIcon accountName={accountName} iconType={'graphs'} />
              : <BrandedIcon accountName={accountName} iconType={'people'} />
            }
          </span>
          <div>
            <h2 className="h6 mb-0">Differentiate Yourself</h2>
            <p>
              Boost revenue and close more sales by offering comprehensive
              coverage
            </p>
          </div>
        </Col>
        <Col lg="6" className={styles.whySellPoints}>
          <span className={styles.ratingsIcon}>
            <BrandedIcon accountName={accountName} iconType={'ratings'} />
          </span>
          <div>
            <h2 className="h6 mb-0">Build Customer Loyalty</h2>
            <p>
              Gain a customer for life by building loyalty and trust through
              quality service
            </p>
          </div>
        </Col>
      </Row>
      <Row className="m-0">
        <Col lg="6" className={styles.whySellPoints}>
          <span>
            {accountNameToLower !== 'ge' 
              ? <BrandedIcon accountName={accountName} iconType={'lock'} />
              : <BrandedIcon accountName={accountName} iconType={'revenuehand'} />
            }
          </span>
          <div>
            <h2 className="h6 mb-0">Lock in Service Revenue</h2>
            <p>
              Establish guaranteed service revenue with our competitive
              reimbursement rate options
            </p>
          </div>
        </Col>
        <Col lg="6" className={styles.whySellPoints}>
          <span className={styles.checkboxesIcon}>
            <BrandedIcon accountName={accountName} iconType={'advantagecheck'} />
          </span>
          <div>
            <h2 className="h6 mb-0">Coverage Flexibility</h2>
            <p className="mb-4">
              Select from multiple coverage, reimbursement and sales options for
              you and your customer
            </p>
          </div>
        </Col>
      </Row>
      {accountName.toLowerCase() === "lennox" &&
        (
          <>
            <h1 className="h5 mb-2">Two Simple Purchase Paths</h1>
            <p className={styles.purchasePath}>
              <b>{getBrandContent(accountName).clientName} Warranty Your Way</b>{" "}
              provides the ability to seamlessly choose your desired
              manufacturer's warranty and{" "}
              <b>{getBrandContent(accountName).programName}</b> upgraded coverage,
              all while registering{" "}
              <b>{getBrandContent(accountName).clientName}</b> products.
            </p>
            <p className={styles.purchasePath}>
              The <b>{getBrandContent(accountName).programName}</b> portal is easy
              to access from <b>{getBrandContent(accountName).clientSite}</b> and
              provides a simple, user friendly way to purchase{" "}
              <b>{getBrandContent(accountName).programName}</b> coverage options.
            </p>
          </>
        )
      }
      {(accountName.toLowerCase() !== "lennox" &&
        accountName.toLowerCase() !== "trane" &&
        accountName.toLowerCase() !== "ge") &&
        (
          <>
            <h1 className="h5 mb-2">Order Processing Made Easy</h1>
            <ul>
              <li className="mb-2">
                {getBrandContent(accountName).advantageProgramName} applies
                pre-selected labor coverage automatically every time you register
                equipment, eliminating the administration burden of order
                processing.
              </li>
              <li className="mb-2">
                {getBrandContent(accountName).porProgramName} allows you to apply
                coverage to individual registrations using the{" "}
                {getBrandContent(accountName).programName} Portal.
              </li>
              <li>
                The {getBrandContent(accountName).programName} Portal is easy to
                access from <b>{getBrandContent(accountName).clientSite}</b>. It
                provides a simple way to submit post registration sales (new and
                existing installations) and manage contracts.
              </li>
            </ul>
          </>
        )
      }
      {accountName.toLowerCase() === "trane" &&
        (
          <>
            <h1 className="h5 mb-2">Order Processing Made Easy</h1>
            <ul>
              <li className="mb-2">
                {getBrandContent(accountName).specialityProgramName} is a program that
                offers discounted rates (up to 25%) when you commit to offering a plan
                on every new install. Each time a customer renews their plan, your
                reimbursement rate will increase. Requires a minimum of 120 plans sold annually.
              </li>
              <li className="mb-2">
                {getBrandContent(accountName).programName} offers two plan
                types {getBrandContent(accountName).standardPlanName} and {getBrandContent(accountName).valuePlanName} {getBrandContent(accountName).pricingLevelName}s. {getBrandContent(accountName).valuePlanName} {getBrandContent(accountName).pricingLevelName}s
                offer the most aggressively discounted rates (up to 40%) with two reimbursement rates options.
              </li>
              <li>
                The {getBrandContent(accountName).programName} Portal is easy to access
                from {getBrandContent(accountName).clientSite}. It provides a simple way to submit post registration sales
                (new and existing installations) and manage contracts.
              </li>
            </ul>
          </>
        )
      }
      {accountName.toLowerCase() === "ge" &&
        (
          <>
            <h1 className="h5 mb-2">Promote Business Growth and Customer Satisfaction</h1>
            <div className="mb-2">
              {getBrandContent(accountName).programName}'s extended service agreements are a great way to add revenue to your business without requiring additional equipment inventory or overhead costs. While the systems you install provides a product to your customer,
              including an extended service agreement builds a lifetime relationship with them.
            </div>
            <div className="mb-2">
              Including an extended service agreement with your original proposal helps your customer recognize it as an added benefit,
              rather than an added cost at the end of the sales process.
            </div>
          </>
        )
      }
    </>
  );
};

export default WhySell