import React, { Component } from "react";
import { Container, Card, CardBody, Row, Col, Label, Input, FormGroup } from "reactstrap";
import classNames from "classnames";
import { CardDetails } from '../components/Sales/PaymentInformation/CardDetails';
import { PaymentMethod } from '../components/Sales/PaymentInformation/PaymentMethod';
import { OrderSummary } from '../components/Sales/PaymentInformation/OrderSummary';
import { PaymentEntityType, PaymentOptionType } from '../components/Global/PaymentConstants';
import styles from './PaymentContainer.module.css'
import { dataLayerPush } from '../components/Global/helpers'

export class PaymentContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCardDetails: false,
            paymentAmount: this.props.amount,
            taxAmount: this.props.taxAmount,
            gstAmount: this.props.gstTotal,
            pstAmount: this.props.pstTotal,
            customerInfo: this.props.customerInfo,
            products: this.props.productList,
            packageData: this.props.packageData,
            dealerPartyId: this.props.dealerPartyId,
            email: this.props.emailDealer,
            profileModel: this.props.profileModel,
            accountId: this.props.accountId,
            countryCode: this.props.isCanadaState ? "CA" : ""
        }

        this.cardDetails = React.createRef();
        this.paymentMethod = React.createRef();

        this.state.toggleCardDetails = this.toggleCardDetails.bind(this);
        this.checkForValidationErrors = this.checkForValidationErrors.bind(this);

    }

    componentDidMount = () => {
        dataLayerPush('Pageview', {
            pageTitle: "Sales Payment",
            pagePath: "/sales/payment"
        })

        const assetPackagesForGtm = [];
        (this.props.assetPackagesData || []).map((value, i) => assetPackagesForGtm.push({
            item_name: value?.name,
            quantity: this.props.assetPackagesData.length ?? 0,
            product_qty: value?.assets?.length,
            price: value?.planPrice,
            retail: value?.equipmentRetailPrice,
            reimbursement: '',
            type: '',
            term: value?.selectedYears,
            products: value.assets?.map(e => ({
                brand: e?.brand,
                prod_description: this.props.productTableData.find(
                    x => x.assetId == e.assetId
                ).prodDescription
            }))
        }))
        //console.log({ x: this.state.assetPackagesData, assetPackagesForGtm })
        dataLayerPush(null, { ecommerce: null }) // clear last ecommerce data
        dataLayerPush('add_payment_info', {
            ecommerce: {
                tax: this.props.taxAmount,
                value: this.props.amount,
                currency: this.props.currency,
                items: [
                    ...assetPackagesForGtm
                ]
            }
        })
    }

    toggleCardDetails = (show) => {
        this.setState(prev => ({
            showCardDetails: show
        }));
    }

    checkForValidationErrors = () => {
        if (this.state.showCardDetails) {
            return this.cardDetails.current.checkForValidationErrors();
        }
    }

    pay = async () => {

        // if show card details is true, they are entering a new credit card number
        if (this.state.showCardDetails) {
            if (!this.checkForValidationErrors()) {
                let result = await this.cardDetails.current.pay();
                if (result.isSuccessful)
                    this.props.onSuccessfulPayment();
                else {
                    if (this.props.onFailedPayment !== undefined) {
                        this.props.onFailedPayment();
					}
				}
            }
        }
        else {
            let result = await this.paymentMethod.current.payBySavedMethod();
            if (result.isSuccessful)
                this.props.onSuccessfulPayment();
            else {
                if (this.props.onFailedPayment !== undefined) {
                    this.props.onFailedPayment();
                }
			}
        }
    }

    render() {
        var contractIds = this.props.contracts?.map(contract => contract.contractId.toString());
        var paymentTotal = this.state.paymentAmount + this.state.taxAmount;
        return (
            <Container className={classNames("contentWrapper", styles.contentWrapperBottom)}>
                <h1 className={`h4 ${styles.pageHeader}`}>Payment Information</h1>
                <Row className="multiColWrapper">
                    <Col lg="8"> 
                        <PaymentMethod 
                            toggleCardDetails={this.toggleCardDetails}
                            paymentAmount={paymentTotal}
                            ref={this.paymentMethod}
                            partyId={this.state.dealerPartyId}
                            showPaymentMethod = {true}
                            showCardDetails={false}
                            chargedEntityType={PaymentEntityType.Contract}
                            chargedEntityIds={contractIds}
                            billingAddress={this.props.installationAddress}
                        />                                                   
                        {this.state.showCardDetails &&
                            <Card>
                                <CardBody>
                                    <CardDetails 
                                    visible={this.state.showCardDetails}
                                    ref={this.cardDetails}
                                    isCanadaState={this.props.isCanadaState}
                                    amount={paymentTotal}
                                    billingAddress={this.props.installationAddress}
                                    mailingAddress={this.props.installationAddress}
                                    chargedEntityType={PaymentEntityType.Contract}
                                    chargedEntityIds={contractIds}
                                    partyId={this.state.dealerPartyId}
                                    savedCardType={PaymentOptionType.preferred}
                                    showPaymentDetails={false}
                                    customerInfo={this.state.customerInfo}
                                    accountId={this.props.accountId}
                                    countryId={this.props.isCanadaState ? 2 : 1}
                                    />
                                </CardBody>
                            </Card>
                            }
                    </Col>
                    <OrderSummary
                        paymentAmount={this.state.paymentAmount}
                        taxAmount={this.state.taxAmount}
                        gst={this.state.gstAmount}
                        pst={this.state.pstAmount}
                        profileModel={this.state.profileModel}
                        phone={this.state.profileModel.contacts[0]?.phoneNumber}
                        email={this.state.email}
                        checkForValidationErrors={this.checkForValidationErrors}
                        payCallback={this.pay}
                        displayPayButton={false}
                        countryCode={this.state.countryCode}/>
                </Row>
            </Container>
        );
    }
}
