import React, { useState, useRef, useContext } from "react";
import { Container, Col, Input, FormGroup } from "reactstrap";
import classNames from "classnames";
import styles from "./Login.module.css";
import SimpleReactValidator from "simple-react-validator";
import { SessionContext } from "../context/SessionContext";

let loginContainer = styles.loginContainer;

const ForgotPassword = (props) => {
  const sessionContext = useContext(SessionContext);
  const { accountName } = sessionContext;
  const accountNameToLower = accountName.toLowerCase();

  const [formData, setFormData] = useState({
    dealearId: "",
  });
  const [validationCheck, setValidationCheck] = useState(false);
  const [isDelearExists, setIsDelearExists] = useState(true);
  const [isSendEmailInProgress, setIsSendEmailInProgress] = useState(false);
  const [valid, setValid] = useState(false);

  const [, forceUpdate] = useState();

  const simpleValidator = useRef(
    new SimpleReactValidator({
      messages: {
        required: ":attribute is required.",
        email: "Enter a valid email",
      },
      validators: {
        is_exists: {
          message: ":attribute not found.",
          rule: (val, params, validator) => {
            return isDelearExists;
          },
        },
        invalid: {
          message: `Invalid :attribute`,
          rule: (val) => {
            return val === true;
          },
        },
      },
      element: (message) => (
        <div className="errorMsg">
          {message.charAt(0).toUpperCase() + message.slice(1)}
        </div>
      ),
    })
  );

  const handleInputChange = (e) => {
    let { name, value } = e.target;

    setIsDelearExists(true);
    setValidationCheck(true);
    simpleValidator.current.showMessages();
    forceUpdate(1);

    simpleValidator.current.showMessageFor("DealerId");
    const pattern = /^[-0-9a-zA-Z]+$/;
    if (!value.match(pattern)) {
      setValid(true);
    } else {
      simpleValidator.current.purgeFields();
      setValid(false);
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const ForgotPass = (e) => {
    e.preventDefault();
    setIsDelearExists(true);

    if (simpleValidator.current.allValid() && !valid) {
      setIsSendEmailInProgress(true);
      setValidationCheck(true);
      let route = "dealer/ResetPasswordByDealerId/" + formData.dealerId;
      fetch(route, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) => {
        setIsSendEmailInProgress(false);
        if (response.status === 200) {
          response
            .text()
            .then((data) => {
              if (data == "true") {
                setIsDelearExists(true);
                props.history.push({
                  pathname: "/verifycode",
                  state: {
                    dealerId: formData.dealerId,
                  },
                });
              } else {
                setIsDelearExists(false);
                setValidationCheck(true);
                simpleValidator.current.showMessages();
                forceUpdate(1);
              }
            })
            .catch((err) => console.log(err));
        } else if (response.status === 404) {
          setIsDelearExists(false);
          setValidationCheck(true);
          simpleValidator.current.showMessages();
          forceUpdate(1);
        }
      });
    } else {
      setValidationCheck(true);
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <div className={styles.vendorForgotPass}>
      <Container className={styles.mainContainer}>
        <Col md="12" lg="12">
          <Col lg="6" md="12" xs="12" className={classNames(loginContainer, "p-0", "mb-0")}>
            <h1 className="h3 mb-1">
              {accountNameToLower === 'ge' && 'Create/'}
              Forgot Password
            </h1>
          </Col>
          <Col lg="5" md="8" xs="12" className={classNames("p-0", "pb-3", styles.infoText)}>
            <p className="mb-0">
              Please enter the Dealer ID associated with your account. We will
              send password reset instructions to the email address associated
              with this account.
            </p>
          </Col>
          <Col lg="4" md="5" className={classNames(loginContainer, "p-0", "pb-3")}>
            <FormGroup
              className={(
                  ((!simpleValidator.current.check(formData.dealerId, "required|is_exists") ||
                    !isDelearExists) && validationCheck) || valid
                ) && "form-error"
              }
            >
              <label for="dealerId" className="form-label">
                Dealer ID
              </label>
              <span aria-hidden="true" className="required-indicator">
                *
              </span>
              <Input
                name="dealerId"
                type="text"
                className="form-control"
                defaultValue={formData.dealerId}
                onChange={handleInputChange}
                disabled={isSendEmailInProgress}
              />
              {simpleValidator.current.message(
                "dealerId",
                formData.dealerId,
                "required|is_exists",
                {
                  messages: {
                    required: "Dealer ID Is required.",
                  },
                }
              )}
              {simpleValidator.current.check(
                formData.dealerId,
                "required|is_exists"
              ) &&
                !isDelearExists &&
                validationCheck && (
                  <div className="errorMsg" aria-live="polite">
                    The Dealer ID entered does not match our records.
                  </div>
                )}
              {valid &&
                simpleValidator.current.message(
                  "DealerId",
                  formData.dealerId,
                  "invalid",
                  {
                    messages: {
                      invalid: "Please enter a valid Dealer ID.",
                    },
                  }
              )}
            </FormGroup>
            <button
              className={classNames("btn-primary")}
              onClick={ForgotPass}
              disabled={isSendEmailInProgress}
            >
              {accountNameToLower === 'ge' ? 'Send Email' : 'SEND EMAIL'}              
            </button>
            <Col md="12" className={styles.enrollNow}>
              <a href="/login">Back to Login</a>
            </Col>
          </Col>
        </Col>
      </Container>
    </div>
  );
};

export { ForgotPassword };