import React, { useState, useContext } from 'react';
import { Card, CardHeader, CardBody, Row, Col, FormGroup, Label } from "reactstrap"
import { SessionContext } from '../../context/SessionContext';
import { useClaimInvoiceStore } from "../../components/InvoiceTables/lib/state/ClaimInvoiceStore";
import styles from './Claim.module.css'
import moment from 'moment';
import StaticClaimInvoiceForm from './StaticClaimInvoiceForm.jsx';
import { InvoiceController } from '../InvoiceTables/Table/InvoiceController';
import { UploadServiceOrderModal } from '../../components/Modals/UploadServiceOrderModal/UploadServiceOrderModal.jsx';
import BootstrapTable from "react-bootstrap-table-next";
import { ReactComponent as DownloadIcon } from "../../assets/images/download-arrow.svg"
import { useEffect } from 'react';
import AIGStatusBadge from '../shared/StatusBadge/StatusBadge';
import { PriceInput } from "../../components/InvoiceTables/Formatters/PriceInput";
import { widthFormatter } from '../../components/InvoiceTables/Formatters/widthFormatter';
import { COLUMNS } from '../InvoiceTables/lib/config';

export const StaticClaim = ({ contract }) => {
    const [inValidationMode, setInValidationMode] = useState(false);
    const [uploadDocument, setUploadDocument] = useState(false);
    const [isOpen, setIsOpen] = useState(true);
    const [allFiles, setAllFiles] = useState([]);
    const [claimData, setClaimData] = useState([]);
    const [assetOptions, setAssetOptions] = useState([]);
    const [repairOptions, setRepairOptions] = useState([]);
    const [tableObjects, setTableObjects] = useState([]);
    const [isViewOnly, setIsViewOnly] = useState(true);
    const { getCurrentTabAndSubtab } = useContext(SessionContext)
    const { invoiceId, assetId } = getCurrentTabAndSubtab().subtab;
    const currentInvoice = useClaimInvoiceStore((state) => state[invoiceId]);
    const { claimNumber, contractNumber, clientId } = getCurrentTabAndSubtab().subtab || {};
    const [tax, setTax] = useState(false);
    const [alternateContract, setAlternateContract] = useState([])
    const [forceUpdate, setForceUpdate] = useState(true);

    useEffect(() => {
        if (contractNumber) {
            fetch('contract/GetSpecificContract/' + contractNumber + "/" + clientId).then(e => e.json()).then(data => {
                setAlternateContract(data)
            })
        }
    }, [contractNumber])

    const getAssetAttributes = (assetId) => {
        fetch(`asset/GetAttributesByAssetIdSummary/${assetId}`).then(res => {
            if (res.ok) {
                res.json().then(data => {
                    setAssetOptions(data)
                });
            }
        });
    }

    const loadRepairCodes = async () => {
        fetch(`asset/GetAllRepairCode`).then(res => {
            if (res.ok) {
                res.json().then(data => {
                    setRepairOptions(data)
                });
            }
        });
    }

    useEffect(() => {
        loadRepairCodes();
    }, [])


    useEffect(() => {
        getAssetAttributes(assetId);
    }, [assetId])

    useEffect(() => {
        const id = contract?.party?.partyAddressModel?.[0]?.stateId;
        if (id) {
            const clientId = "hvac"
            getTax(id, clientId)
            window.scrollTo(0, 0)
        }
    }, [contract])

    const handleUploadDocument = (e) => {
        e.preventDefault();
        setUploadDocument(true);
        setIsOpen(true);
    }

    const handleDownloadDocument = (colIndex, e) => {
        e.preventDefault();
        const blob = allFiles?.[colIndex]?.file;
        const link = document.createElement('a')
        const url = URL.createObjectURL(blob)
        link.href = url
        link.download = blob.name
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(url)
    }

    const getTax = async (id, clientId) => {
        try {
            const res = await fetch(`tax/gettaxdetailsbystateid/${id}/${clientId}`);
            const resJson = await res.json();
            setTax(resJson);
        } catch (e) {
            console.log({ 'error fetching tax data': e })
        }
    }

    const defaultSortedBy = [{
        dataField: "dateEntered",
        order: "desc"  // or asc
    }];

    const headerFormatter = (column, index, { sortElement, filterElement }) => {
        const { order } = sortElement.props;
        return (
            <span className={`${order === "asc" ? "ascending" : ''} ${order === "desc" ? "descending" : ''}`}>
                {column.text}
            </span>
        );
    }

    const closeModal = () => {
        setIsOpen(false);
    }

    const handleFileUpload = (file) => {
        setAllFiles([...allFiles, ...file]);
        closeModal();
    }

    const ActionFormatter = (column, row, colIndex) => {
        const documentName = row.documentName;
        let mimeType = 'application/pdf';
        if (documentName.includes('png')) {
            mimeType = 'image/png';
        } else if (documentName.includes('jpeg') || documentName.includes('jpg')) {
            mimeType = 'image/jpeg';
        }

        return <div className="d-flex">
            {row.documentData ?
                <a className={styles.button} download href={`data:${mimeType};base64,${row.documentData ?? row.file}`}>
                    <DownloadIcon
                        alt='Download'
                        className={styles.icon}
                    />
                </a>
                :
                <a className={styles.button} >
                    <DownloadIcon
                        alt='Download'
                        className={styles.icon}
                        onClick={(e) => handleDownloadDocument(colIndex, e)}
                    />
                </a>}
        </div>
    }

    const columnsVendor = [
        {
            dataField: "dateEntered",
            text: "Date",
            classes: styles.columnDate,
            sort: true,
            headerFormatter: headerFormatter,
            formatter: (col, row) => {
                return moment(col).format(contract?.country === 'US' ? 'MM/DD/YYYY hh:mm A' : 'DD/MM/YYYY hh:mm A');
            }
        },
        {
            dataField: "documentName",
            text: "Document Name",
            classes: styles.columnDate,
            sort: true,
        },
        {
            dataField: "typeOfDocument",
            text: "Type of Document",
            classes: styles.columnType,
            sort: true,
            formatter: (col, row) => {
                return row?.documentTypeName ?? col;
            }
        },
        {
            dataField: "fileSize",
            text: "Size",
            sort: true,
        },
        {
            dataField: "notes",
            text: "Additional Notes",
            sort: true,
        },
        {
            dataField: "actions",
            dummyField: true,
            text: "",
            formatter: ActionFormatter,
        }
    ]

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(`/claim/GetSubmittedClaimSummaryByClaimNumber/${claimNumber}/hvac`);
            const staticClaim = await response.json();
            setClaimData(staticClaim)
            setAllFiles(staticClaim?.documentModel?.map(e => ({ ...e, file: e?.documentData })));
        };
        fetchData();
    }, []);

    const getUniqueListBy = (arr, key) => {
        return [...new Map(arr?.map(item => [item[key], item])).values()]
    }

    useEffect(() => {
        const tables = claimData?.authorizationDisplayModel?.map(e => e.authorizationType);
        const tableObjs = tables?.reduce((acc, curr) => {
            const filtered = claimData?.authorizationDisplayModel?.filter(e => e.authorizationType === curr);
            const partsAllowanceFlag = filtered?.some(e => e?.description === 'partsAllowance');
            const laborSelectedFlag = filtered?.some(e => e?.description === 'laborSelectRate');
            let columns = COLUMNS[curr];
            let data = [];
            if (curr === 'labor' && !laborSelectedFlag) {
                const reconstructedLaborData = filtered?.map(e => {
                    const assetSelected = assetOptions?.filter(opt => opt.assetId === e.assetId);
                    const componentOptions = contract?.repairMatrixInfo || alternateContract?.repairMatrixInfo;
                    const componentSelected = componentOptions?.filter(opt => opt.attributeId === e.attributeId)?.[0];
                    const repairSelected = componentOptions?.filter(rp => rp.repairActionId === e.repairActionId);
                    return {
                        asset: {
                            options: assetOptions,
                            selected: assetSelected
                        },
                        component: {
                            options: componentOptions,
                            selected: [componentSelected]
                        },
                        hours: e.qty,
                        invoiceId: '',
                        rate: e.unitPrice,
                        repair: {
                            options: repairOptions,
                            selected: repairSelected
                        },
                        repariHours: '',
                        total: parseFloat(e.requestedAmount).toFixed(2)
                    }

                });
                columns = COLUMNS[curr];
                data = reconstructedLaborData;
            } if (curr === 'labor' && laborSelectedFlag) {
                const reconstructedLaborSelectRateData = filtered?.map(e => {
                    const assetSelected = assetOptions?.filter(opt => opt.assetId === e.assetId);
                    const componentOptions = contract?.repairMatrixInfo || alternateContract?.repairMatrixInfo;
                    const repairCodeOptions = contract?.repairMatrixInfo || alternateContract?.repairMatrixInfo;
                    const componentSelected = componentOptions?.filter(opt => opt.attributeId === e.attributeId)?.[0];
                    const repairSelected = repairCodeOptions?.filter(rp => rp.repairActionId === e.repairActionId)?.[0];
                    const repairCode = componentOptions?.filter(c => c.repairActionId === e.repairActionId)?.[0]?.repairCode;
                    return {
                        asset: {
                            options: assetOptions,
                            selected: assetSelected
                        },
                        component: {
                            options: componentOptions,
                            selected: [componentSelected]
                        },
                        rate: e.unitPrice,
                        repair: {
                            options: repairOptions,
                            selected: [repairSelected]
                        },

                        repairHours: e.qty,
                        total: parseFloat(e.requestedAmount).toFixed(2),
                        perc: `${e.percentage}%`,
                        repairCode: repairCode ?? ''
                    }

                });
                columns = COLUMNS['laborSelectRate'];
                data = reconstructedLaborSelectRateData;
            }
            else if (curr === 'partsmarkup' || curr === 'partsMarkup') {
                const descriptionOptions = currentInvoice?.partsMarkup?.data?.[0]?.description?.options ?? [];
                const reconstructedPartsMarkupData = filtered?.map(e => {
                    const assetSelected = assetOptions?.filter(opt => opt.assetId === e.assetId);
                    const descOptions = contract?.assets?.[0]?.parts;
                    const descSelected = descOptions?.filter(d => d.attributeName === e.description);
                    return {
                        qty: e.qty,
                        oemWarranty: e.oemWarranty,
                        asset: {
                            options: assetOptions,
                            selected: assetSelected
                        },
                        markup: e.markupAmount,
                        price: e.unitPrice,
                        total: parseFloat(e.requestedAmount).toFixed(2),
                        partNumber: e.partNumber,
                        description: descOptions ? { options: descOptions, selected: descSelected } : {
                            options: [],
                            selected: [{ value: e.description, display: e.description }],
                        }
                    }
                });
                columns = COLUMNS[curr];
                data = reconstructedPartsMarkupData;
            } else if (curr === 'parts' && !partsAllowanceFlag) {
                const descriptionOptions = currentInvoice?.partsMarkup?.data?.[0]?.description?.options ?? [];
                const reconstructedPartsData = filtered?.map(e => {
                    const assetSelected = assetOptions?.filter(opt => opt.assetId === e.assetId);
                    return {
                        qty: e.qty,
                        requestedAmount: '',
                        asset: {
                            options: assetOptions,
                            selected: assetSelected
                        },
                        oemWarranty: e.oemWarranty,
                        markup: e.markupAmount,
                        price: e.unitPrice,
                        total: parseFloat(e.requestedAmount).toFixed(2),
                        partNumber: e.partNumber,
                        description: e.description
                    }
                });
                columns = COLUMNS[curr];
                data = reconstructedPartsData;
            } else if (curr === 'parts' && partsAllowanceFlag) {
                const reconstructedPartsAllowanceData = filtered?.map(e => {
                    return {
                        name: 'Parts Allowance',
                        total: parseFloat(e.requestedAmount).toFixed(2)
                    }
                });
                columns = COLUMNS['partsAllowance'];
                data = reconstructedPartsAllowanceData;
            } else if (curr === 'trip') {
                const reconstructedTripData = filtered?.map(e => {
                    return {
                        qty: e.qty,
                        total: parseFloat(e.requestedAmount).toFixed(2),
                        reason: e.reason,
                        trip: "Round Trip",
                        tripRate: e.unitPrice
                    }
                });
                columns = COLUMNS[curr];
                data = reconstructedTripData;
            } else if (curr.includes('labortax')) {
                const filtered = claimData?.authorizationDisplayModel?.filter(e => e.authorizationType.includes('labortax'));
                const taxColumns = Array.isArray(tax) ? (tax || [])?.map(e => e.taxTypeModel?.taxTypeName) : [];
                let custColumns = [
                    {
                        dataField: 'name',
                        align: 'left',
                        text: '',
                        style: {
                            textTransform: 'capitalize'
                        }
                    },
                    {
                        dataField: "total",
                        text: 'Total',
                        align: 'right',
                        style: widthFormatter('invoiceTotalCol'),
                        footerAlign: 'right',
                        formatter: (col, row, i) => {
                            return `$${Number(col)?.toFixed(2)}`;
                        }
                    }];

                if (contract?.country === 'CA') {
                    const items = [
                        {
                            dataField: "gst",
                            text: 'GST',
                            headerAlign: 'left',
                            align: 'right',
                            style: widthFormatter('invoiceTotalCol'),
                            hidden: !(taxColumns || []).filter(e => e === 'GST')?.includes('GST'),
                            formatter: (col, row, i) => (
                                <PriceInput cell={col} row={row} rowIndex={i} fieldName="gst" tableType="tax" />
                            )
                        },
                        {
                            dataField: "hst",
                            text: 'HST',
                            headerAlign: 'left',
                            align: 'right',
                            style: widthFormatter('invoiceTotalCol'),
                            hidden: !(taxColumns || []).filter(e => e === 'HST')?.includes('HST'),
                            formatter: (col, row, i) => (
                                <PriceInput cell={col} row={row} rowIndex={i} fieldName="hst" tableType="tax" />
                            )
                        },
                        {
                            dataField: "pst",
                            headerAlign: 'left',
                            text: 'PST',
                            align: 'right',
                            style: widthFormatter('invoiceTotalCol'),
                            hidden: !(taxColumns || []).filter(e => e === 'PST')?.includes('PST'),
                            formatter: (col, row, i) => (
                                <PriceInput cell={col} row={row} rowIndex={i} fieldName="pst" tableType="tax" />
                            )
                        },
                        {
                            dataField: "qst",
                            headerAlign: 'left',
                            text: 'QST',
                            align: 'right',
                            style: widthFormatter('invoiceTotalCol'),
                            hidden: !(taxColumns || []).filter(e => e === 'QST')?.includes('QST'),
                            formatter: (col, row, i) => (
                                <PriceInput cell={col} row={row} rowIndex={i} fieldName="qst" tableType="tax" />
                            )
                        }
                    ]
                    custColumns.splice(1, 0, ...items);
                }
                const reconstructedTaxData = filtered?.map(e => {
                    const isQstExists = e.authorizationType.includes('taxqst');
                    const isHstExists = e.authorizationType.includes('taxhst');
                    const isPstExists = e.authorizationType.includes('taxpst');
                    const isGstExists = e.authorizationType.includes('taxgst');
                    let taxData = {
                        invoiceId: '',
                        name: curr.replace(/tax\w*/g, ' Tax'),
                        total: parseFloat(e.requestedAmount).toFixed(2),
                    };
                    if (isQstExists) {
                        taxData = {
                            ...taxData,
                            qst: parseFloat(e.requestedAmount).toFixed(2)
                        }
                    };
                    if (isPstExists) {
                        taxData = {
                            ...taxData,
                            pst: parseFloat(e.requestedAmount).toFixed(2)
                        }
                    };
                    if (isHstExists) {
                        taxData = {
                            ...taxData,
                            hst: parseFloat(e.requestedAmount).toFixed(2)
                        }
                    };
                    if (isGstExists) {
                        taxData = {
                            ...taxData,
                            gst: parseFloat(e.requestedAmount).toFixed(2)
                        }
                    };
                    return taxData;
                });
                const merged = reconstructedTaxData.reduce((acc, invoice) => {
                    const { name, total, gst = '0', pst = '0', hst = '0', qst = '0' } = invoice;
                    acc[name] = acc[name] || { name, total: 0, gst: 0, pst: 0, qst: 0, hst: 0 };
                    acc[name].total += parseFloat(total);
                    acc[name].gst += parseFloat(gst);
                    acc[name].pst += parseFloat(pst);
                    acc[name].hst += parseFloat(hst);
                    acc[name].qst += parseFloat(qst);
                    return acc;
                }, {});
                columns = getUniqueListBy(custColumns, 'dataField');
                data = Object.values(merged);
            } else if (curr.includes('triptax')) {
                const filtered = claimData?.authorizationDisplayModel?.filter(e => e.authorizationType.includes('triptax'));
                const taxColumns = Array.isArray(tax) ? (tax || [])?.map(e => e.taxTypeModel?.taxTypeName) : [];
                let custColumns = [
                    {
                        dataField: 'name',
                        align: 'left',
                        text: '',
                        style: {
                            textTransform: 'capitalize'
                        }
                    },
                    {
                        dataField: "total",
                        text: 'Total',
                        align: 'right',
                        style: widthFormatter('invoiceTotalCol'),
                        footerAlign: 'right',
                        formatter: (col, row, i) => {
                            return `$${Number(col)?.toFixed(2)}`;
                        }
                    }];

                if (contract?.country === 'CA') {
                    const items = [
                        {
                            dataField: "gst",
                            text: 'GST',
                            headerAlign: 'left',
                            align: 'right',
                            style: widthFormatter('invoiceTotalCol'),
                            hidden: !(taxColumns || []).filter(e => e === 'GST')?.includes('GST'),
                            formatter: (col, row, i) => (
                                <PriceInput cell={col} row={row} rowIndex={i} fieldName="gst" tableType="tax" />
                            )
                        },
                        {
                            dataField: "hst",
                            text: 'HST',
                            headerAlign: 'left',
                            align: 'right',
                            style: widthFormatter('invoiceTotalCol'),
                            hidden: !(taxColumns || []).filter(e => e === 'HST')?.includes('HST'),
                            formatter: (col, row, i) => (
                                <PriceInput cell={col} row={row} rowIndex={i} fieldName="hst" tableType="tax" />
                            )
                        },
                        {
                            dataField: "pst",
                            headerAlign: 'left',
                            text: 'PST',
                            align: 'right',
                            style: widthFormatter('invoiceTotalCol'),
                            hidden: !(taxColumns || []).filter(e => e === 'PST')?.includes('PST'),
                            formatter: (col, row, i) => (
                                <PriceInput cell={col} row={row} rowIndex={i} fieldName="pst" tableType="tax" />
                            )
                        },
                        {
                            dataField: "qst",
                            headerAlign: 'left',
                            text: 'QST',
                            align: 'right',
                            style: widthFormatter('invoiceTotalCol'),
                            hidden: !(taxColumns || []).filter(e => e === 'QST')?.includes('QST'),
                            formatter: (col, row, i) => (
                                <PriceInput cell={col} row={row} rowIndex={i} fieldName="qst" tableType="tax" />
                            )
                        }
                    ]
                    custColumns.splice(1, 0, ...items);
                }
                const reconstructedTaxData = filtered?.map(e => {
                    const isQstExists = e.authorizationType.includes('qst');
                    const isHstExists = e.authorizationType.includes('hst');
                    const isPstExists = e.authorizationType.includes('pst');
                    const isGstExists = e.authorizationType.includes('gst');
                    let taxData = {
                        invoiceId: '',
                        name: curr.replace(/tax\w*/g, ' Tax'),
                        total: parseFloat(e.requestedAmount).toFixed(2)
                    };
                    if (isQstExists) {
                        taxData = {
                            ...taxData,
                            qst: parseFloat(e.requestedAmount).toFixed(2)
                        }
                    };
                    if (isPstExists) {
                        taxData = {
                            ...taxData,
                            pst: parseFloat(e.requestedAmount).toFixed(2)
                        }
                    };
                    if (isHstExists) {
                        taxData = {
                            ...taxData,
                            hst: parseFloat(e.requestedAmount).toFixed(2)
                        }
                    };
                    if (isGstExists) {
                        taxData = {
                            ...taxData,
                            gst: parseFloat(e.requestedAmount).toFixed(2)
                        }
                    };
                    return taxData;
                });

                const merged = reconstructedTaxData.reduce((acc, invoice) => {
                    const { name, total, gst = '0', pst = '0', hst = '0', qst = '0' } = invoice;
                    acc[name] = acc[name] || { name, total: 0, gst: 0, pst: 0, qst: 0, hst: 0 };
                    acc[name].total += parseFloat(total);
                    acc[name].gst += parseFloat(gst);
                    acc[name].pst += parseFloat(pst);
                    acc[name].hst += parseFloat(hst);
                    acc[name].qst += parseFloat(qst);
                    return acc;
                }, {});
                columns = getUniqueListBy(custColumns, 'dataField');
                data = Object.values(merged);
            } else if (curr.includes('partstax')) {
                const filtered = claimData?.authorizationDisplayModel?.filter(e => e.authorizationType.includes('partstax'));
                const taxColumns = Array.isArray(tax) ? (tax || [])?.map(e => e.taxTypeModel?.taxTypeName) : [];
                let custColumns = [
                    {
                        dataField: 'name',
                        align: 'left',
                        text: '',
                        style: {
                            textTransform: 'capitalize'
                        }
                    },
                    {
                        dataField: "total",
                        text: 'Total',
                        align: 'right',
                        style: widthFormatter('invoiceTotalCol'),
                        footerAlign: 'right',
                        formatter: (col, row, i) => {
                            return `$${Number(col)?.toFixed(2)}`;
                        }
                    }];

                if (contract?.country === 'CA') {
                    const items = [
                        {
                            dataField: "gst",
                            text: 'GST',
                            headerAlign: 'left',
                            align: 'right',
                            style: widthFormatter('invoiceTotalCol'),
                            hidden: !(taxColumns || []).filter(e => e === 'GST')?.includes('GST'),
                            formatter: (col, row, i) => (
                                <PriceInput cell={col} row={row} rowIndex={i} fieldName="gst" tableType="tax" />
                            )
                        },
                        {
                            dataField: "hst",
                            text: 'HST',
                            headerAlign: 'left',
                            align: 'right',
                            style: widthFormatter('invoiceTotalCol'),
                            hidden: !(taxColumns || []).filter(e => e === 'HST')?.includes('HST'),
                            formatter: (col, row, i) => (
                                <PriceInput cell={col} row={row} rowIndex={i} fieldName="hst" tableType="tax" />
                            )
                        },
                        {
                            dataField: "pst",
                            headerAlign: 'left',
                            text: 'PST',
                            align: 'right',
                            style: widthFormatter('invoiceTotalCol'),
                            hidden: !(taxColumns || []).filter(e => e === 'PST')?.includes('PST'),
                            formatter: (col, row, i) => (
                                <PriceInput cell={col} row={row} rowIndex={i} fieldName="pst" tableType="tax" />
                            )
                        },
                        {
                            dataField: "qst",
                            headerAlign: 'left',
                            text: 'QST',
                            align: 'right',
                            style: widthFormatter('invoiceTotalCol'),
                            hidden: !(taxColumns || []).filter(e => e === 'QST')?.includes('QST'),
                            formatter: (col, row, i) => (
                                <PriceInput cell={col} row={row} rowIndex={i} fieldName="qst" tableType="tax" />
                            )
                        }
                    ]
                    custColumns.splice(1, 0, ...items);
                }
                const reconstructedTaxData = filtered?.map(e => {
                    const isQstExists = e.authorizationType.includes('qst');
                    const isHstExists = e.authorizationType.includes('hst');
                    const isPstExists = e.authorizationType.includes('pst');
                    const isGstExists = e.authorizationType.includes('gst');
                    let taxData = {
                        invoiceId: '',
                        name: curr.replace(/tax\w*/g, ' Tax'),
                        total: parseFloat(e.requestedAmount).toFixed(2),
                    };
                    if (isQstExists) {
                        taxData = {
                            ...taxData,
                            qst: parseFloat(e.requestedAmount).toFixed(2)
                        }
                    };
                    if (isPstExists) {
                        taxData = {
                            ...taxData,
                            pst: parseFloat(e.requestedAmount).toFixed(2)
                        }
                    };
                    if (isHstExists) {
                        taxData = {
                            ...taxData,
                            hst: parseFloat(e.requestedAmount).toFixed(2)
                        }
                    };
                    if (isGstExists) {
                        taxData = {
                            ...taxData,
                            gst: parseFloat(e.requestedAmount).toFixed(2)
                        }
                    };
                    return taxData;
                });

                const merged = reconstructedTaxData.reduce((acc, invoice) => {
                    const { name, total, gst = '0', pst = '0', hst = '0', qst = '0' } = invoice;
                    acc[name] = acc[name] || { name, total: 0, gst: 0, pst: 0, qst: 0, hst: 0 };
                    acc[name].total += parseFloat(total);
                    acc[name].gst += parseFloat(gst);
                    acc[name].pst += parseFloat(pst);
                    acc[name].hst += parseFloat(hst);
                    acc[name].qst += parseFloat(qst);
                    return acc;
                }, {});
                columns = getUniqueListBy(custColumns, 'dataField');
                data = Object.values(merged);
            }

            // We'll create a objects that supports to show the table in invoice controller
            acc = {
                ...acc,
                [partsAllowanceFlag ? 'partsAllowance' : curr]: {
                    columns: columns ?? [{ dataField: 'name', id: '' }],
                    data,
                    tableType: curr
                }
            };
            return acc;
        }, []);


        const filterTaxData = Object.entries(tableObjs ?? {})?.reduce((acc, val) => {
            if (val?.[0]?.includes('tax')) {
                acc = {
                    ...(acc ?? {}),
                    data: getUniqueListBy([...acc?.data, ...val[1]?.data], 'name'),
                    columns: getUniqueListBy([...acc?.columns, ...val[1]?.columns], 'dataField'),
                    tableType: 'tax'
                }
            }
            return acc;
        }, { data: [], columns: [] });

        const finalTableObjs = Object.entries(tableObjs ?? {})?.reduce((acc, e) => {
            if (e?.[0]?.includes('tax')) return acc;
            acc = {
                ...acc,
                [e[0]]: e[1]
            }
            return acc;
        }, {});

        if (Object.keys(finalTableObjs ?? {})?.length > 0) {
            setTableObjects({
                ...finalTableObjs,
                tax: filterTaxData
            });
        }
        setForceUpdate(!forceUpdate)
    }, [claimData, contract, alternateContract]);

    return (
        <>
            <form>
                <Card>
                    <CardHeader>
                        <Row>
                            <Col sm="4">Claim:  {claimNumber} <AIGStatusBadge statusId={claimData.eventStatusId} removeMargin statusType={'event'} /></Col>
                        </Row>
                    </CardHeader>
                    <CardBody className={styles.Invoice}>
                        <StaticClaimInvoiceForm contract={contract} claimData={claimData} />
                        <div className={styles.disableInvoice}>
                            {(contract || alternateContract) && <InvoiceController isViewOnly={isViewOnly} tableObjects={tableObjects} claimData={claimData} inValidationMode={inValidationMode} />}
                        </div>
                    </CardBody>
                    <Col sm="6" className="ml-2">
                        <ul className="address mb-0">
                            <li>
                                <Label for="invoiceNumber" className="form-label">Additional Details About Requested Rates</Label>
                                <span aria-hidden="true" className="required-indicator">*</span>
                            </li>
                            <li>
                                {claimData.additionalDetails}
                            </li>
                        </ul>
                    </Col>

                    <Col className="ml-2" md="12">
                        <hr></hr>
                        <h4 mt="3">Upload Document</h4>
                        <p>Please upload a copy of the Service Work Order Invoice and any additional documents that would be beneficial during the review of this claim.</p>
                        <button disabled={(claimData.claimStatusId === 4 || claimData.claimStatusId === 5) ? true : false} className="btn-secondary mb-3" onClick={handleUploadDocument}>Upload Document</button>
                        <hr></hr>
                        <h4 mt="3">Uploaded Documents</h4>
                        <div className={styles.uploadedFilesTable}>
                            <BootstrapTable
                                keyField='dateEntered'
                                data={allFiles}
                                defaultSortedBy={defaultSortedBy}
                                columns={columnsVendor}
                                bordered={false}
                                bootstrap4
                                noDataIndication={!allFiles.length > 0 && 'No Documents Uploaded'}
                                wrapperClasses={"table-responsive-lg"}
                            />
                        </div>
                    </Col>
                </Card>
                <div className="btnWrapper">
                    <button className="btn-primary ml-3" type={"submit"}>Cancel Claim</button>
                </div>
            </form>
            {uploadDocument && <UploadServiceOrderModal contract={contract} isOpen={isOpen} onEdit={closeModal} handleFileUpload={handleFileUpload} />}
        </>
    )
}