import React, { useEffect, useState } from 'react';
import { Container, Card, Col } from 'reactstrap'
import { Row } from 'react-bootstrap';
import moment from 'moment';
import DownloadButton from "../shared/IconButtons/DownloadButton";
import { phoneFormat, convertToCamelCase } from '../shared/util';
import ComfortShieldLogo from './Logo/ComfortShield_Logo.svg';
import SnadminLogo from './Logo/Snadmin_Logo.svg';
import { getTranslation } from '../../components/Global/helpers';

const CertificateCoverage = ({ styles, assetDetails }) => {
    const [cocTranslation, setCocTranslation] = useState({});

    const dateFormat = (date) => {
        return moment(date).format('DD/MM/yyyy')
    }

    useEffect(() => {
        getTranslation('Vendor/FR/components/COC.json')
            .then(response => response.json())
            .then(data => {
                setCocTranslation(data);
            })
    }, [])

    return (
        <Container className="contentWrapper">
            <Card className={styles.cardWrapper}>
                <Row className={`m-0 pl-3 pr-3  pt-3 ${styles.contentWrapper}`}>
                    <Col>
                        <div className={styles.imageWrapper}>
                            <img
                                tabIndex={0}
                                className={styles.dealerImageContainer}
                                src={ComfortShieldLogo}
                                alt="comfortshield"
                            />
                        </div>
                        <div >
                            <span className={styles.addressHeader}>DÉTAILLANT:</span>
                        </div>
                        <div>{assetDetails?.dealerName}</div>
                        <div>{assetDetails?.dealerAddress1}</div>
                        {assetDetails?.dealerAddress2 && <div> {assetDetails?.dealerAddress2}</div>}
                        <div>{`${assetDetails?.dealerCity} ${assetDetails?.dealerStateAbbreviation} ${assetDetails.dealerZip}`} </div>
                    </Col>
                    <Col>
                        <div className={styles.imageWrapper}>
                            <img
                                tabIndex={0}
                                className={styles.customerImageContainer}
                                src={SnadminLogo}
                                alt="SNAdmin"
                            />
                        </div>
                        <div>
                            <span className={styles.addressHeader}>DÉLIVRÉ À:</span>
                        </div>
                        <div>{assetDetails?.customerName}</div>
                        <div>{assetDetails?.customerAddress1}</div>
                        {assetDetails?.customerAddress2 && <div> {assetDetails?.customerAddress2}</div>}
                        <div>{`${assetDetails?.customerCity} ${assetDetails?.customerStateAbbreviation} ${assetDetails.customerZip}`} </div>
                    </Col>
                    <Col className={styles.downloadWrapper}>
                        {/* <DownloadButton
                            alt='Download'
                            onClick={() => { }}
                        /> */}
                    </Col>
                </Row>
                <div className={`p-3 ${styles.contentWrapper}`}>
                    <div className={styles.boxWrapper}>
                        <div className='p-3'>
                            <p className={styles.headerWrapper}>CERTIFICAT DE GARANTIE</p>
                            <div className={styles.flexCenter}>
                                <div className={styles.containerWrapper}>
                                    Merci d’avoir acheté un contrat de service prolongé Comfort Shield. Ce contrat de service
                                    prolongé est un outil précieux pour vous aider à éviter les dépenses de réparation
                                    imprévues.
                                </div>
                            </div>
                            <div className={styles.flexCenter}>
                                <hr className={styles.horizontalWrapper} />
                            </div>

                            <div className={styles.wrapperDetails}>
                                <div>
                                    <span className={styles.wrapperTitle}>
                                        <strong>NUMÉRO DE MODÈLE</strong>
                                    </span>
                                    <span>
                                        <strong>{`: ${assetDetails?.contractNumber}`}</strong>
                                    </span>
                                </div>
                                <div>
                                    <span className={styles.wrapperTitle}>
                                        <strong>PRIX DE DÉTAIL DU CONTRAT</strong>
                                    </span>
                                    <span>
                                        <strong>{`: Consultez votre reçu d´achat ou votre facture.`}</strong>
                                    </span>
                                </div>
                                <div>
                                    <span className={styles.wrapperTitle}>
                                        <strong>TYPE DE GARANTIE</strong>
                                    </span>
                                    <span>
                                        <strong>{`: ${cocTranslation?.typeOfCoverage?.[convertToCamelCase(assetDetails?.coverageType)] || assetDetails?.coverageType}`}</strong>
                                    </span>
                                </div>
                            </div>
                            <Row className={`${styles.productDetailWrapper} m-0`}>
                                <Row className='m-0'>
                                    <Col className={`${styles.textContainer} col-2`}><strong>NOM DU PRODUIT</strong></Col>
                                    <Col className={`${styles.textContainer} col-2`} ><strong>FABRICANT</strong></Col>
                                    <Col className={`${styles.textContainer} col-2`}><strong>NUMÉRO DE MODÈLE</strong></Col>
                                    <Col className={`${styles.textContainer} col-2`}><strong>NUMÉRO DE SÉRIE</strong></Col>
                                    <Col className={`${styles.textContainer} col-2`}><strong>DATE EN VIGUEUR</strong></Col>
                                    <Col className={`${styles.textContainer} col-2`}><strong>DATE D’ÉCHÉANCE</strong></Col>
                                </Row>
                                {assetDetails?.assetDetails?.map(data => {
                                    return <Row className='m-0'>
                                        <Col className='col-2'>{cocTranslation?.assetInfo?.[convertToCamelCase(data.assetName)]}</Col>
                                        <Col className='col-2'>{data.brandName}</Col>
                                        <Col className='col-2'>{data.modelNumber}</Col>
                                        <Col className='col-2'>{data.serialNumber}</Col>
                                        <Col className='col-2'>{dateFormat(data.startDate)}</Col>
                                        <Col className='col-2'>{dateFormat(data.expirationDate)} </Col>
                                    </Row>
                                })}
                            </Row>
                            <Row className='m-0'>
                                <div className={styles.wrapperHeader}>{`POUR UNE RÉPARATION, APPELEZ: ${phoneFormat(assetDetails?.dealerPhone)}`}</div>
                            </Row>
                            <Row className='m-0'>
                                <Row className={styles.wrapperContainerHeader}>Merci!</Row>
                                <Row className='m-0 ml-4 mr-4'>
                                    <p>
                                        RENSEIGNEMENTS PERSONNELS. Vous consentez à ce que nous recueillions, utilisions et divulguions lesrenseignements
                                        personnels que vous nous fournissez en lien avec le présent Certificat afin de vous fournir des servicesrelatifs
                                        au présent Certificat ainsi que des services administratifs reliés au contrat de service après-vente. Vosrenseignements
                                        personnels seront stockés au 650 Missouri Avenue, Jeffersonville (Indiana) aux États-Unis 47130. Deplus,
                                        vous nous concédez le droit d’échanger ou de transférer ces renseignements personnels à des filiales et à despartenaires
                                        tarificateurs pouvant traiter vos renseignements personnels en notre nom pour les mêmes besoins, ainsiqu’à
                                        d’autres organisations dans les cas requis ou permis par la Loi. Vos renseignements personnels peuvent êtredivulgués,
                                        traités et stockés à l’extérieur du Canada. Par conséquent, ils peuvent être mis à la disposition des autoritésgouvernementales
                                        en vertu d’une ordonnance légale. Vos renseignements personnels peuvent également être transférésà
                                        un tiers ou à de tierces parties pour être utilisés aux mêmes fins dans le cas où nous vendrions ou affecterions noséléments
                                        d’actif ou activités à un tel tiers ou à de telles tierces parties, et en relation avec la diligence raisonnable etau
                                        terme de toute telles transaction ou transaction financière par nous faisant l’objet de tels éléments d’actif ou de tellesactivités.
                                    </p>
                                </Row>
                            </Row>
                        </div>
                    </div>
                </div>
            </Card>
        </Container>
    )
}

export default CertificateCoverage;
